import React from 'react';
import Jumbotron from '../../Components/jumbotron/index';

export function JumbotronContainer() {
  return (
    <>
      <div className='Desktop-Container'>
        <div className='Jumbo'>
          <Jumbotron.Container className='beauty1'>
            <Jumbotron direction='row'>
              <Jumbotron.Pane>
                <Jumbotron.Text>
                  <p>
                    You’ve no doubt forged your life in a certain way that has
                    brought you here; it’s now time to choose an <span>E</span>
                    xquisite one of a kind masterpiece design. Something that
                    you feel resonates with your own personal energy and
                    vibration ~!
                  </p>
                  <p>
                    Beautify and re-create the space that surrounds you. Exotic
                    Modern Art for you and others to feel and enjoy.
                  </p>
                  <p>
                    A true solid <span>S</span>tatement of success!
                  </p>
                </Jumbotron.Text>
              </Jumbotron.Pane>
              <Jumbotron.Pane>
                <Jumbotron.Image
                  // src='https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/beauty1.jpg'
                  src = 'https://ayushaopus.eu-central-1.linodeobjects.com/709135.47f4eac30.jpg'
                  // src={require('../../assets/img/beauty/beauty1.jpg').default}
                  alt=''
                  onContextMenu={(e) => e.preventDefault()}
                />
                <div className='overlay'></div>
              </Jumbotron.Pane>
            </Jumbotron>
          </Jumbotron.Container>

          {/* Jumbotron 2 */}
          <Jumbotron.Container className='beauty2'>
            <Jumbotron direction='row-reverse'>
              <Jumbotron.Pane>
                <Jumbotron.Text>
                  <p>
                    These artistic masterpieces have such a raw industrial feel
                    that can only be felt by such size and weight.
                  </p>
                  <p>
                    The many different finishes I can create are second to none
                    and will become a one of a kind masterpiece just for you!
                  </p>
                  <p>
                    Nothing comes close to these intricate carvings when you’re
                    looking to re-create the energy around you with this
                    magnitude.
                  </p>
                </Jumbotron.Text>
              </Jumbotron.Pane>
              <Jumbotron.Pane>
                <Jumbotron.Image
                  // src={require('../../assets/img/beauty/beauty2.jpg').default}
                  src = 'https://ayushaopus.eu-central-1.linodeobjects.com/914660.f05bef817.jpg'
                  // src='https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/beauty2.jpg'
                  alt=''
                  onContextMenu={(e) => e.preventDefault()}
                />
                <div className='overlay'></div>
              </Jumbotron.Pane>
            </Jumbotron>
          </Jumbotron.Container>

          {/* Jumbotron 3 */}
          <Jumbotron.Container className='beauty3'>
            <Jumbotron direction='row'>
              <Jumbotron.Pane>
                <Jumbotron.Text>
                  <p>
                    Imagine standing next to a <span>T</span>owering 10 foot
                    carving weighing more than 2000 lbs. (900+ kg.)~.!
                  </p>
                  <p>
                    The words “<span>S</span>olid” and “<span>U</span>
                    nbreakable” do not even come close when such a piece is in
                    your foyer, hotel or prayer room; words that probably
                    describe your nature and resolve.
                  </p>
                  <p>
                    Depending on your vision, these artistic creations can be
                    seen and felt as a motivational piece each and every day!
                  </p>
                </Jumbotron.Text>
              </Jumbotron.Pane>
              <Jumbotron.Pane>
                <Jumbotron.Image
                  // src={require('../../assets/img/beauty/beauty3.jpg').default}
                  src = 'https://ayushaopus.eu-central-1.linodeobjects.com/243708.8f7e8571t.jpg'
                  // src='https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/beauty3.jpg'
                  alt=''
                  onContextMenu={(e) => e.preventDefault()}
                />
                <div className='overlay'></div>
              </Jumbotron.Pane>
            </Jumbotron>
          </Jumbotron.Container>

          <Jumbotron.Container className='beauty4'>
            <Jumbotron direction='row-reverse'>
              <Jumbotron.Pane>
                <Jumbotron.Text>
                  <p>
                    Truly Limitless Possibilities… let your imagination beautify
                    your space ~!
                  </p>
                </Jumbotron.Text>
              </Jumbotron.Pane>
            </Jumbotron>
          </Jumbotron.Container>
        </div>
      </div>

      <div className='Mobile-Container'>
        <div className='Jumbo'>
          <div className='beauty1'>
            <div>
              <div>
                <Jumbotron.Text>
                  <p>
                    You’ve no doubt forged your life in a certain way that has
                    brought you here; it’s now time to choose an <span>E</span>
                    xquisite one of a kind masterpiece design. Something that
                    you feel resonates with your own personal energy and
                    vibration ~!
                  </p>
                  <p>
                    Beautify and re-create the space that surrounds you. Exotic
                    Modern Art for you and others to feel and enjoy.
                  </p>
                  <p>
                    A true solid <span>S</span>tatement of success!
                  </p>
                </Jumbotron.Text>
              </div>
              <div>
                <Jumbotron.Image
                  // src='https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/beauty1.jpg'
                  src = 'https://ayushaopus.eu-central-1.linodeobjects.com/709135.47f4eac30.jpg'
                  // src={require('../../assets/img/beauty/beauty1.jpg').default}
                  alt=''
                  onContextMenu={(e) => e.preventDefault()}
                />
                <div className='overlay'></div>
              </div>
            </div>
          </div>

          {/* Jumbotron 2 */}
          <div className='beauty2'>
            <div>
              <div>
                <Jumbotron.Text>
                  <p>
                    These artistic masterpieces have such a raw industrial feel
                    that can only be felt by such size and weight.
                  </p>
                  <p>
                    The many different finishes I can create are second to none
                    and will become a one of a kind masterpiece just for you!
                  </p>
                  <p>
                    Nothing comes close to these intricate carvings when you’re
                    looking to re-create the energy around you with this
                    magnitude.
                  </p>
                </Jumbotron.Text>
              </div>
              <div>
                <Jumbotron.Image
                  // src={require('../../assets/img/beauty/beauty2.jpg').default}
                  src = 'https://ayushaopus.eu-central-1.linodeobjects.com/914660.f05bef817.jpg'
                  // src='https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/beauty2.jpg'
                  alt=''
                  onContextMenu={(e) => e.preventDefault()}
                />
                
                <div className='overlay'></div>
              </div>
            </div>
          </div>

          {/* Jumbotron 3 */}
          <div className='beauty3'>
            <div>
              <div>
                <Jumbotron.Text>
                  <p>
                    Imagine standing next to a <span>T</span>owering 10 foot
                    carving weighing more than 2000 lbs. (900+ kg.)~.!
                  </p>
                  <p>
                    The words “<span>S</span>olid” and “<span>U</span>
                    nbreakable” do not even come close when such a piece is in
                    your foyer, hotel or prayer room; words that probably
                    describe your nature and resolve.
                  </p>
                  <p>
                    Depending on your vision, these artistic creations can be
                    seen and felt as a motivational piece each and every day!
                  </p>
                </Jumbotron.Text>
              </div>
              <div>
                <Jumbotron.Image
                  // src={require('../../assets/img/beauty/beauty3.jpg').default}
                  src = 'https://ayushaopus.eu-central-1.linodeobjects.com/243708.8f7e8571t.jpg'
                  // src='https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/beauty3.jpg'
                  alt=''
                  onContextMenu={(e) => e.preventDefault()}
                />
                <div className='overlay'></div>
              </div>
            </div>
          </div>

          <div className='beauty4'>
            <div>
              <div>
                <Jumbotron.Text>
                  <p>
                    Truly Limitless Possibilities… let your imagination beautify
                    your space ~!
                  </p>
                </Jumbotron.Text>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
