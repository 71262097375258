import React, { useEffect, useState } from 'react';

// import mainVideos from '../assets/video/mainVideo.mp4';

import { Container } from '../styles/globalStyle';
import { withRouter } from 'react-router';
import Links from '../Components/Shared/Links';
import { HomeAccordian, CopyrightAccordion } from '../Components/Homepage/HomeAbout';
import { analytics } from '../firebase/index'

const MainVideo = ({ onCursor, setLinkSection, location }) => {
  const [data, setData] = useState();
  useEffect(() => {
    analytics.logEvent('Main_video_visited')
    const query = new URLSearchParams(location.search);
    let data = {};

    for (let params of query.entries()) {
      data[params[0]] = params[1];
    }
    setData(data.t);
  }, []);
  return (
    <>
      <Container className='main-video'>
        {/* <video
          className={'video'}
          controls
          controlsList='nodownload'
          // src={`https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/mainVideo.mp4#t=${data}`}
          // src={`${mainVideos}#t=${data}`}
          src = {`https://ayushaopus.eu-central-1.linodeobjects.com/167930.d18a4dbe.mp4#t=${data}`}
          // muted
          muted
          playsinline webkit-playsinline webkit-playsinline="true" playsinline="true"
          autoPlay
          loop
          onContextMenu={(e) => e.preventDefault()}
        ></video> */}
        <div 
        style={{padding:'58.25% 0 0 0',position:'relative'}}>
        <iframe 
        src={`https://player.vimeo.com/video/671596620?h=2047d83b0c&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;loop=1&amp;autoplay=1#t=${data}`} 
        frameborder="0" 
        allow="autoplay; fullscreen; picture-in-picture" 
        allowfullscreen 
        style={{position:'absolute',top:'0',left:'0',width:'100%',height:'100%'}} 
        title="167930.d18a4dbe2.mp4">
        </iframe>
        </div>
        <script src="https://player.vimeo.com/api/player.js"></script>
        {/* <div className='overlayVideo'></div> */}
      </Container>
      <Links setLinkSection={setLinkSection} onCursor={onCursor} />
      <HomeAccordian onCursor={onCursor} />
      {/* <CopyrightAccordion onCursor={onCursor} /> */}
    </>
  );
};

export default withRouter(MainVideo);
