import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import useWindowSize from '../../hooks/useWindowSize';
// Styled Components
import { Container, Flex } from '../../styles/globalStyle.js';
//Framer Motion
import { motion, useAnimation } from 'framer-motion';
//Home Component
import {
  HomeAboutSection,
  About,
  Services,
  AccordionHeader,
  AccordionContent,
  AccordionIcon,
} from '../../styles/homeStyles';
//Context
import { useGlobalStateContext } from '../../context/globalContext';
//Scroll Observer
import { useInView } from 'react-intersection-observer';

// import { ReactComponent as CopyrightSVG } from '../assets/svg/copyright.svg';
// import { ReactComponent as TrademarkSVG } from '../assets/svg/trademark.svg';

import { CopyrightSVG, Trademark } from '../../assets/svg/social-icons';


// Accordion Data
const accordionIds = [
  {
    id: 0,
    title: 'About',
    results: [
      { name: 'Artist', link: '/artist' },
      { name: 'Meaning', link: '/meaning' },
      { name: 'Philosophy', link: 'philosophy' },
      { name: 'Vision & Philanthropy', link: '/vision-philanthropy' },
      { name: 'Beauty', link: '/beauty' },
      { name: 'Metal', link: '/metal' },
      { name: 'Commitment', link: '/commitment' },
      { name: 'Contact', link: '/contact' },
    ],
  },
  {
    id: 1,
    title: 'Scenes',
    results: [
      { name: 'Foyer', link: '/main-video?t=0' },
      { name: 'Master Bedroom', link: '/main-video?t=155' },
      { name: 'Bathroom', link: '/main-video?t=203' },
      { name: 'Guest Bedroom', link: 'vision&/main-video?t=228' },
      { name: 'Elevator', link: '/main-video?t=260' },
      { name: 'Prayer Room', link: '/main-video?t=299' },
      { name: 'Majlis', link: '/main-video?t=341' },
      { name: 'Courtyard', link: '/main-video?t=450' },
      { name: 'Airplane', link: '/main-video?t=651' },
      { name: 'Living & Dining Room', link: '/main-video?t=711' },
      { name: 'Yacht', link: '/main-video?t=822' },
      { name: 'Garage', link: '/main-video?t=945' },
    ],
  },
  {
    id: 2,
    title: 'Important Information',
    results: [
      { name: 'Terms of Use Agreement', link: '/copyright' },
      { name: 'Disclaimer', link: '/copyright' },
      { name: 'Caveats', link: '/copyright' },
      { name: 'Copyright', link: '/copyright#copyright' },
      { name: 'Trademark', link: '/copyright#trademarks' },
    ],
  },
];
const copyrightAccordionData = [
  {
    id: 0,
    title: 'Important Information',
    results: [
      { name: 'Terms of Use Agreement', link: '/copyright' },
      { name: 'Disclaimer', link: '/copyright' },
      { name: 'Caveats', link: '/copyright' },
      { name: 'Copyright', link: '/copyright#copyright' },
      { name: 'Trademark', link: '/copyright#trademarks' },
    ],
  },
];
const accordionHome = [
  {
    id: 0,
    title: 'Home',
    results: [
      {
        name: 'Unless You claim Extraordinary ~  ordinary claims you.',
        link: '/',
      },
    ],
  },
  {
    id: 1,
    title: 'Important Information',
    results: [
      { name: 'Terms of Use Agreement', link: '/copyright' },
      { name: 'Disclaimer', link: '/copyright' },
      { name: 'Caveats', link: 'copyright' },
      { name: 'Copyright', link: '/copyright' },
      { name: 'Trademark', link: '/copyright' },
    ],
  },
];
const accordionCopyright = [
  {
    id: 0,
    title: 'IMPORTANT INFORMATION',
    results: [
      { name: 'Terms of Use Agreement', link: '/copyright' },
      { name: 'Disclaimer', link: '/copyright' },
      { name: 'Caveats', link: 'copyright' },
      { name: 'Copyright', link: '/copyright' },
      { name: 'Trademark', link: '/copyright' },
    ],
  }
];

const HomeAbout = ({ onCursor }) => {
  //Default state, using number for our id. Which ever the number/id is in the state. That will be opened.
  const [expanded, setExpanded] = useState(false);
  const animation = useAnimation();
  const [aboutRef, inView] = useInView({
    triggerOnce: true,
    // Giving our scrollwheel additional 300px before executing animation
    rootMargin: '-300px',
  });

  useEffect(() => {
    if (inView) {
      animation.start('visible');
    }
  }, [animation, inView]);

  return (
    <HomeAboutSection>
      <Container>
        <Flex alignTop center className={'flex'}>
          <Services>
            {/* <h3>Services</h3> */}
            {accordionIds.map((details, index) => (
              <Accordion
                key={index}
                details={details}
                expanded={expanded}
                setExpanded={setExpanded}
                onCursor={onCursor}
              />
            ))}
          </Services>
        </Flex>
      </Container>
    </HomeAboutSection>
  );
};

const Accordion = ({ details, expanded, setExpanded, onCursor }) => {
  const isOpen = details.id === expanded;
  const [hovered, setHovered] = useState(false);
  const { currentTheme } = useGlobalStateContext();
  return (
    <>
      <AccordionHeader
        initial={false}
        onClick={() => setExpanded(isOpen ? false : details.id)}
        whileHover={{
          color: currentTheme === 'dark' ? '#ffffff' : '#000000',
        }}
        onHoverStart={() => setHovered(!hovered)}
        onHoverEnd={() => setHovered(!hovered)}
        onMouseEnter={() => onCursor('hovered')}
        onMouseLeave={onCursor}
      >
        <AccordionIcon>
          <motion.span
            style={{
              background:
                isOpen || hovered
                  ? currentTheme === 'dark'
                    ? 'white'
                    : 'black'
                  : null,
            }}
            animate={{ rotate: isOpen || hovered ? 0 : 45, x: 3 }}
            transition={{ duration: 0.2, ease: [0.6, 0.05, -0.01, 0.9] }}
          ></motion.span>
          <motion.span
            style={{
              background:
                isOpen || hovered
                  ? currentTheme === 'dark'
                    ? 'white'
                    : 'black'
                  : null,
            }}
            animate={{ rotate: isOpen || hovered ? 0 : -45, x: -3 }}
            transition={{ duration: 0.2, ease: [0.6, 0.05, -0.01, 0.9] }}
          ></motion.span>
        </AccordionIcon>
        {details.title}
      </AccordionHeader>
      <AccordionContent
        key='content'
        animate={{ height: isOpen ? '100%' : '0' }}
        transition={{ duration: 0.8, ease: [0.6, 0.05, -0.01, 0.9] }}
      >
        {details.results.map((item) => (
          <Link to={`${item.link}`}>
            {' '}
            <span
              whileHover={{
                color: currentTheme === 'dark' ? '#ffffff' : '#000000',
              }}
              key={item.link}
            >
              {item.name}
            </span>{' '}
          </Link>
        ))}
      </AccordionContent>
    </>
  );
};

const HomeAccordian = ({ onCursor, page }) => {
  //Default state, using number for our id. Which ever the number/id is in the state. That will be opened.
  const [expanded, setExpanded] = useState(false);
  const animation = useAnimation();
  const [aboutRef, inView] = useInView({
    triggerOnce: true,
    // Giving our scrollwheel additional 300px before executing animation
    rootMargin: '-300px',
  });

  useEffect(() => {
    if (inView) {
      animation.start('visible');
    }
  }, [animation, inView]);

  return (
    <HomeAboutSection className={page === 'contact' ? 'contact' : ''}>
      <Container>
        <Flex alignTop center className={'flex'}>
          <Services>
            {accordionHome.map((details, index) => (
              <AccordionHome
                key={index}
                details={details}
                expanded={expanded}
                setExpanded={setExpanded}
                onCursor={onCursor}
              />
            ))}
          </Services>
        </Flex>
      </Container>
    </HomeAboutSection>
  );
};

const AccordionHome = ({ details, expanded, setExpanded, onCursor }) => {
  const isOpen = details.id === expanded;
  const [hovered, setHovered] = useState(false);
  const { currentTheme } = useGlobalStateContext();
  const size = useWindowSize();
  return (
    <>
      <AccordionHeader
        initial={false}
        onClick={() => setExpanded(isOpen ? false : details.id)}
        whileHover={{
          color: currentTheme === 'dark' ? '#ffffff' : '#000000',
        }}
        onHoverStart={() => setHovered(!hovered)}
        onHoverEnd={() => setHovered(!hovered)}
        onMouseEnter={() => onCursor('hovered')}
        onMouseLeave={onCursor}
      >
        <AccordionIcon>
          <motion.span
            style={{
              background:
                isOpen || hovered
                  ? currentTheme === 'dark'
                    ? 'white'
                    : 'black'
                  : null,
            }}
            animate={{ rotate: isOpen || hovered ? 0 : 45, x: 3 }}
            transition={{ duration: 0.2, ease: [0.6, 0.05, -0.01, 0.9] }}
          ></motion.span>
          <motion.span
            style={{
              background:
                isOpen || hovered
                  ? currentTheme === 'dark'
                    ? 'white'
                    : 'black'
                  : null,
            }}
            animate={{ rotate: isOpen || hovered ? 0 : -45, x: -3 }}
            transition={{ duration: 0.2, ease: [0.6, 0.05, -0.01, 0.9] }}
          ></motion.span>
        </AccordionIcon>
        {details.title}
      </AccordionHeader>
      <AccordionContent
        key='content'
        animate={{ height: isOpen ? '100%' : '0' }}
        transition={{ duration: 0.8, ease: [0.6, 0.05, -0.01, 0.9] }}
      >
        {details.results.map((item) => (
          <Link to={`${item.link}`}>
            {' '}
            <span
              className='home'
              whileHover={{
                color: 'eb002a',
                // color: currentTheme === 'dark' ? '#ffffff' : '#000000',
              }}
              key={item.link}
            >
              {item.name}
              {/* Unless You claim <span className='span'>E</span>xtraordinary{' '}
              {size.width < 550 ? (
                <br />
              ) : (
                <ectraSpace style={{ marginLeft: '6px' }} />
              )}
              ~ ordinary claims you. */}
            </span>{' '}
          </Link>
        ))}
      </AccordionContent>
    </>
  );
};

const CopyrightHomeAccordian = ({ onCursor, page }) => {
  //Default state, using number for our id. Which ever the number/id is in the state. That will be opened.
  const [expanded, setExpanded] = useState(false);
  const animation = useAnimation();
  const [aboutRef, inView] = useInView({
    triggerOnce: true,
    // Giving our scrollwheel additional 300px before executing animation
    rootMargin: '-300px',
  });

  useEffect(() => {
    if (inView) {
      animation.start('visible');
    }
  }, [animation, inView]);

  const accordionCopyrightPage = [
    {
      id: 0,
      title: 'Home',
      results: [
        {
          name: 'Unless You claim Extraordinary ~  ordinary claims you.',
          link: '/',
        },
      ],
    },
  ];

  return (  
    <HomeAboutSection className='copyright_home_about'>
    {/* <Container> */}
      <Flex alignTop center className={'flex'}>
        <Services className='copyrightPageAccordian'>
          {accordionCopyrightPage.map((details, index) => (
            <AccordionHome
              key={index}
              details={details}
              expanded={expanded}
              setExpanded={setExpanded}
              onCursor={onCursor}
            />
          ))}
        </Services>
      </Flex>
    {/* </Container> */}
  </HomeAboutSection>
  );
};

const CopyrightAccordion = ({ onCursor }) => {
  //Default state, using number for our id. Which ever the number/id is in the state. That will be opened.
  const [expanded, setExpanded] = useState(false);
  const animation = useAnimation();
  const [aboutRef, inView] = useInView({
    triggerOnce: true,
    // Giving our scrollwheel additional 300px before executing animation
    rootMargin: '-300px',
  });

  useEffect(() => {
    if (inView) {
      animation.start('visible');
    }
  }, [animation, inView]);

  return (
    <HomeAboutSection className='copyrightAccordian'>
      <Container>
        <Flex alignTop center className={'flex'}>
          <Services>
            {/* <h3>Services</h3> */}
            {copyrightAccordionData.map((details, index) => (
              <Accordion
                key={index}
                details={details}
                expanded={expanded}
                setExpanded={setExpanded}
                onCursor={onCursor}
              />
            ))}
          </Services>
        </Flex>
      </Container>
    </HomeAboutSection>
  );
};



const FooterCopyright = ({ onCursor }) => {
  //Default state, using number for our id. Which ever the number/id is in the state. That will be opened.
  const [expanded, setExpanded] = useState(false);
  const animation = useAnimation();
  const [aboutRef, inView] = useInView({
    triggerOnce: true,
    // Giving our scrollwheel additional 300px before executing animation
    rootMargin: '-300px',
  });

  useEffect(() => {
    if (inView) {
      animation.start('visible');
    }
  }, [animation, inView]);

  return (
    // <HomeAboutSection className='copyright_section'>
    //   <Container>
        <Flex alignTop flexEnd className={'flex_copyright'}>
          {/* <Services> */}
            {/* <h3>Services</h3> */}
            {accordionCopyright.map((details, index) => (
              <Copyright
                key={index}
                details={details}
                expanded={expanded}
                setExpanded={setExpanded}
                onCursor={onCursor}
              />
            ))}
          {/* </Services> */}
        </Flex>
    //   </Container>
    // </HomeAboutSection>
  );
};

const Copyright = ({ details, expanded, setExpanded, onCursor }) => {
  const isOpen = details.id === expanded;
  const [hovered, setHovered] = useState(false);
  const { currentTheme } = useGlobalStateContext();
  const size = useWindowSize();
  return (
    <>
      <AccordionHeader
        initial={false}
        onClick={() => setExpanded(isOpen ? false : details.id)}
        whileHover={{
          color: currentTheme === 'dark' ? '#ffffff' : '#000000',
        }}
        onHoverStart={() => setHovered(!hovered)}
        onHoverEnd={() => setHovered(!hovered)}
        onMouseEnter={() => onCursor('hovered')}
        onMouseLeave={onCursor}
        className='copyright'
        style={{
          color: currentTheme === 'dark' ? '#ffffff' : '#000000',
        }}
      >
        <AccordionIcon className='copyright_accoudian_icon'>
          <motion.span
            style={{
              background:
                isOpen || hovered
                  ? currentTheme === 'dark'
                    ? 'white'
                    : 'black'
                  : null,
            }}
            animate={{ rotate: isOpen || hovered ? 0 : 45, x: 3 }}
            transition={{ duration: 0.2, ease: [0.6, 0.05, -0.01, 0.9] }}
          ></motion.span>
          <motion.span
            style={{
              background:
                isOpen || hovered
                  ? currentTheme === 'dark'
                    ? 'white'
                    : 'black'
                  : null,
            }}
            animate={{ rotate: isOpen || hovered ? 0 : -45, x: -3 }}
            transition={{ duration: 0.2, ease: [0.6, 0.05, -0.01, 0.9] }}
          ></motion.span>
        </AccordionIcon>
        {/* {details.title} */}
        {/* <CopyrightSVG />
        <Trademark />  */}
        <div>IMPORTANT INFORMATION </div>
        {/* {size.width < 768 ? (<br />) : null} */}
      </AccordionHeader>
      <AccordionContent
      // className='accordionContentCopyright'
        key='content'
        animate={{ height: isOpen ? '100%' : '0' }}
        transition={{ duration: 0.8, ease: [0.6, 0.05, -0.01, 0.9] }}
      >
        {/* {details.results.map((item) => ( */}
          <Link to='/copyright'>
            {' '}
            <span
              whileHover={{
                color: currentTheme === 'dark' ? '#ffffff' : '#000000',
              }}
            >
              Terms of Use Agreement
            </span>{' '}
          </Link>
          <Link to='/copyright'>
            {' '}
            <span
              whileHover={{
                color: currentTheme === 'dark' ? '#ffffff' : '#000000',
              }}
            >
              Disclaimer
            </span>{' '}
          </Link>
          <Link to='/copyright'>
            {' '}
            <span
              whileHover={{
                color: currentTheme === 'dark' ? '#ffffff' : '#000000',
              }}
            >
              Caveats
            </span>{' '}
          </Link>
          <Link to='/copyright#copyright'>
            {' '}
            <span
              whileHover={{
                color: currentTheme === 'dark' ? '#ffffff' : '#000000',
              }}
            >
              <CopyrightSVG className='accordianSVG' /> Copyright
            </span>{' '}
          </Link>
          <Link to='/copyright#trademarks'>
            {' '}
            <span
              whileHover={{
                color: currentTheme === 'dark' ? '#ffffff' : '#000000',
              }}
            >
              <Trademark className='accordianSVG' /> Trademark
            </span>{' '}
          </Link>
        {/*  ))} */}
      </AccordionContent>
    </>
  );
};


export { HomeAbout, HomeAccordian, FooterCopyright, CopyrightHomeAccordian, CopyrightAccordion };
