import React, { useEffect } from 'react';
import { FullWidth } from '../styles/Project/ProjectContentStyles';
import ProjectBanner from '../Components/Project/ProjectBanner';
import ProjectContent from '../Components/Project/ProjectContent';
import Links from '../Components/Shared/Links';
import { JumbotronContainer } from '../containers/philosophy/index';
import { HomeAccordian, CopyrightAccordion } from '../Components/Homepage/HomeAbout';
import { analytics } from '../firebase/index'

const Philosophy = ({
  onCursor,
  toggleMenu,
  setToggleMenu,
  setLinkSection,
}) => {
  useEffect(() => {
    analytics.logEvent('Philosophy_Page_visited')
    setToggleMenu(false);
  }, []);
  const content = (
    <FullWidth>
      <p>
        I’ve always felt that creating a wonderful environment for people to be
        surrounded by is the key to success which creates a paradise of{' '}
        <span> H</span>ope. I simply like to see people enjoy my art.
      </p>
      <p>
        I aim to be brave in my artistic vision and therefore I am able to
        create the worlds’ largest and most intricate complex designs in metal
        art for you and your loved ones to enjoy. The energy emanating from
        these huge pieces of metal art can be felt with such magnetism as it
        intertwines with your vibration and energy.
      </p>
      <p>
        Let imagination design your surroundings and create a feeling of{' '}
        <span>A</span>bundance..
      </p>
      <p>
        I also specialize and take great pride in making very exotic modern{' '}
        <span>P</span>ublic <span>A</span>rt so that communities are able to
        enjoy such artistic creations that may not be otherwise possible.
      </p>
    </FullWidth>
  );
  return (
    <div>
      <ProjectBanner
        title={'Philosophy'}
        noFeaturedVideo={true}
        toggleMenu={toggleMenu}
        setToggleMenu={setToggleMenu}
        onCursor={onCursor}
      />
      {/* <ProjectContent credits={false} content={content} /> */}
      <JumbotronContainer />

      <Links setLinkSection={setLinkSection} onCursor={onCursor} />
      <HomeAccordian onCursor={onCursor} />
      {/* <CopyrightAccordion onCursor={onCursor} /> */}
    </div>
  );
};

export default Philosophy;
