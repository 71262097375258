import styled from 'styled-components/macro';

export const Item = styled.div`
  display: flex;
  color: white;
  overflow: hidden;
  padding: 0px 5%;
`;

export const Inner = styled.div`
  display: flex;
  align-items: center;
  flex-direction: ${({ direction }) => direction};
  justify-content: space-between;
  max-width: 93.9%;
  // max-width: 1100px;
  margin: auto;
  width: 100%;

  @media (max-width: 500px) {
    max-width: 94%;
  }
  // @media (max-width: 490px) {
  //   max-width: 90%;
  // }

  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;

export const Pane = styled.div`
  width: 45%;

  .overlay {
    height: 600px;
    position: absolute;
    width: 600px
    transform: translateY(-370px);
    @media (max-width: 1000px) {
      width: 85%;
    }
  }

  @media (max-width: 1000px) {
    width: 100%;
    padding: 0 45px;

    &.overlay {
      width: 85%;
    }
  }
`;

export const Title = styled.h1`
  font-size: 50px;
  line-height: 1.1;
  margin-bottom: 8px;

  @media (max-width: 600px) {
    font-size: 35px;
  }
`;

export const SubTitle = styled.h2`
  font-size: 26px;
  font-weight: normal;
  line-height: normal;

  @media (max-width: 600px) {
    font-size: 18px;
  }
`;

export const Image = styled.img`
  max-width: 100%;
  height: auto;

  @media (max-width: 500px) {
    margin-bottom: 15px;
    margin-top: 14px;
  }
  &.AO_LOGO_MEANING {
    // max-width: 450px;
    max-width: 91.5%;
    display: flex;
    justify-content: center;
    margin: auto;

    @media (max-width: 500px) {
      max-width: 270px;
      margin-bottom: -55px;
      margin-top: -35px;
    }
  }

  &.AO_LOGO_VISION {
    max-width: 500px;
    display: flex;
    justify-content: center;
    margin: auto;
    // margin-top: -30px;
    margin-top: -60px;
    margin-bottom: -30px;

    @media (max-width: 500px) {
      max-width: 320px;
      margin: -20px auto -15px;
    }
  }
`;

export const Container = styled.div`
  margin: 20px 0;

  &.artist1 {
    margin: -35px 0 -15px;
  }

  &.artist2 {
    margin: 20px 0 60px;
    @media (max-width: 500px) {
      margin: 20px 0 5px;
    }
  }

  &.artist3 {
    margin-bottom: 90px;
    @media (max-width: 500px) {
      margin-bottom: 5px;
    }
  }

  &.artist4 {
    margin-bottom: -5px;
    @media (max-width: 500px) {
      margin-bottom: 5px;
    }
  }

  &.meaning1 {
    margin: -35px 0 25px;
  }

  &.meaning2 {
    margin-bottom: -40px;
    @media (max-width: 500px) {
      margin-bottom: 0px;
    }
  }

  &.philosophy1 {
    margin-bottom: 90px;

    @media (max-width: 500px) {
      margin-bottom: 50px;
      margin-top: -35px;
    }
  }

  &.philosophy2 {
    margin-bottom: 90px;

    @media (max-width: 500px) {
      margin-bottom: 50px;
      margin-top: -40px;
    }
  }

  &.philosophy3 {
    margin-bottom: -10px;
    @media (max-width: 500px) {
      margin-top: -43px;
      margin-bottom: 0px;
    }
  }

  &.beauty1 {
    margin: -35px 0 -10px 0;
    @media (max-width: 500px) {
      margin: -35px 0 -40px 0;
    }
  }

  &.beauty2 {
    margin-bottom: -5px;
    @media (max-width: 1380px) {
      margin: 50px 0 22px 0;
    }
    @media (max-width: 500px) {
      margin: 50px 0 10px 0;
    }
  }

  &.beauty3 {
    margin-bottom: -10px;
    @media (max-width: 500px) {
      margin-bottom: 10px;
    }
  }

  &.beauty4 {
    margin-bottom: -35px;
    @media (max-width: 500px) {
      margin-bottom: 0px;
    }
  }

  &.metal1 {
    margin-bottom: 85px;
    @media (max-width: 500px) {
      margin: -35px 0 5px 0;
    }
  }

  &.metal2 {
    margin-bottom: 45px;
    @media (max-width: 500px) {
      margin-bottom: -45px;
    }
  }

  &.metal3 {
    margin-bottom: -40px;
    @media (max-width: 1380px) {
      margin: 50px 0 -35px 0;
    }
    @media (max-width: 500px) {
      margin: 50px 0 5px 0;
    }
  }

  &.commitment1 {
    margin: -40px 0;
    @media (max-width: 1380px) {
      margin: -35px 0 -28px 0;
    }
    @media (max-width: 500px) {
      margin: -35px 0 5px 0;
    }
  }

  &.commitment3 {
    margin: 60px 0 50px 0;
    @media (max-width: 500px) {
      margin: 0px 0 -5px 0;
    }
  }

  &.commitment4 {
    margin-bottom: -35px;
    @media (max-width: 500px) {
      margin: 0px 0 5px 0;
    }
  }

  &.vision2 {
    @media (max-width: 1380px) {
      margin: 20px 0 23px 0;
    }
  }
  &.video1 {
    margin: 28px 0 -25px 0;
    @media (max-width: 1000px) {
      // margin-bottom: 5px;
      // margin-top: -35px;
      margin: -35px 0 -30px 0;
    }
  }
  &.video2 {
    margin: -12px 0 30px 0;
    @media (max-width: 1000px) {
      margin-bottom: -39px;
      margin-top: -25px;
    }
  }
  &.video3 {
    margin: 35px 0 28px 0;
    @media (max-width: 1000px) {
      // margin-top: -20px;
      // margin-bottom: 15px;
      margin: -5px 0;
    }
  }
  &.video4 {
    margin: 35px 0 -40px 0;
    @media (max-width: 1000px) {
      // margin-top: -30px;
      // margin-bottom: -25px;
      margin: -10px 0 -15px 0;
    }
  }

  &.contact {
    margin: -40px 0;
    @media (max-width: 500px) {
      margin: -35px 0;
    }
  }

  &.logo_credits {
    margin-bottom: -10px;
  }
  @media (max-width: 1000px) {
    margin: 0;
    ${Item}:last-of-type h2 {
      margin-bottom: 50px;
    }
  }
`;

export const Text = styled.div`
  p {
    color: ${(props) => props.theme.text};
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.5px;

    &.displayNone {
      display: none;
    }

    &.jumbo4text2 {
      margin-top: 30px;
    }

    @media (max-width: 1420px) {
      font-size: 1.3rem;
    }

    @media (max-width: 768px) {
      margin-left: 0;
      // width: 95% !important;
      font-size: 1.2rem;
    }

    @media (max-width: 500px) {
      margin-left: 0;
      // width: 95% !important;
      font-size: 1rem;
      margin-bottom: 12px;
      margin-top: 0;
    }
    // @media (max-width: 400px) {
    //   font-size: 0.9rem;
    // }
  }

  span {
    font-size: 1.9rem;
    font-weight: 700;

    @media (max-width: 1420px) {
      font-size: 1.8rem;
    }

    @media (max-width: 500px) {
      font-size: 1.2rem;
    }
  }

  &.bold {
    align-items: center;
    display: flex;
    justify-content: center;
    color: white;
    font-weight: 700;
    font-size: 1.8rem;
    line-height: 30%;
    p {
      @media (max-width: 500px) {
        font-weight: 700;
        font-size: 1rem;
        text-align: center;
      }
    }
  }

  &.displayNone {
    display: none;
  }

  &.center {
    align-items: center;
    display: flex;
    justify-content: center;
    color: white;
    font-size: 1.5rem;
    line-height: 30%;
    height: 33px;

    &.credit1 {
      margin: 0px 0;
      @media (max-width: 500px) {
        margin: -8px 0;
      }
    }

    &.credit2 {
      margin: 0px 0 80px 0;
      @media (max-width: 500px) {
        margin: -8px 0 80px 0;
      }
    }

    p {
      @media (max-width: 500px) {
        font-size: 0.9rem;
        text-align: center;
      }
      // @media (max-width: 370px) {
      //   font-size: 0.7rem;
      //   text-align: center;
      // }
    }
  }
`;

export const Video = styled.div`
  position: relative;
  width: 100%;
  height: 480px;
  display: block;
  overflow: hidden;
  margin: 30px 0;
  .overlayVideo {
    position: relative;
    width: 100%;
    height: 600px;
    top: -500px;
  }
  @media (max-width: 1000px) {
    height: 270px;
  }

  &.margin_vid2 {
    margin-top: 20px;
    margin-bottom: 35px;
    @media (max-width: 500px) {
      // margin-top: 25px;
      margin-bottom: 65px;
      margin-top: 65px;
    }
  }

  video {
    &.vid_2 {
      height: 270px;
      // @media (max-width: 500px) {
      //   height: 340px;
      //   transform: translateY(-10%);
      // }
    }
  }

  &.margin_vid3 {
    margin-top: 65px;
  }

  &.margin_vid4 {
    margin-top: 65px;
    margin-bottom: 15px;
  }

  &.margin_vid5 {
    margin-top: 55px;
  }

  &.marginTop35 {
    margin-top: 35px;
  }

  &.moreMarginTop {
    margin-top: 60px;
  }

  video {
    height: 450px;
    width: 100%;
    object-fit: cover;
    &.margin_vid_4 {
      margin-top: -10px;
    } 
    &.margin_vid_5 {
      // margin-top: 60px;
      margin-top: 0px;
    } 
    &.margin_top_10 {
      margin-top: 10px;
    }

    @media (max-width: 1000px) {
      height: 270px;
    }
  }
`;

export const VideoText = styled.div`
  display: block;
  align-items: center;
  justify-content: center;
  // max-width: 1100px;
  margin: auto;
  width: 100%;
  margin-bottom: -40px;
  margin-top: 20px;

  &.video2above768{
    p {
      @media (min-width: 720px) {
        margin-top: 40px !important;
        margin-bottom: 70px !important;
      }
    }
  }

  @media (max-width: 500px) {
    // width: 90%;
  }

  p {
    color: ${(props) => props.theme.text};
    font-size: 1.3rem;
    font-weight: 400;
    line-height: 70%;
    letter-spacing: 0.5px;
    text-align: start;

    &.margin-10 {
      margin-top: -10px;
      margin-bottom: 18px;
    }

    // &.lesswidth {
    //   width: 90%;
    //   margin-left: 20px;
    // }

    &.moreLineHeight {
      line-height: 150%;
    }


    span {
      font-size: 1.8rem;
      font-weight: 700;
      @media (max-width: 400px) {
        font-size: 1.4rem;
      }
    }

    @media (min-width: 731px) {
      &.vid5above731 {
        margin-bottom: 60px;
      }
    }


    @media (max-width: 768px) {
      margin-left: 0;
      // width: 95% !important;
      font-size: 1.2rem;
      line-height: 150%;
    }

    @media (max-width: 500px) {
      margin-left: 0;
      // width: 95% !important;
      font-size: 1rem;
      line-height: 150%;
      width: 100%;
    }
    // @media (max-width: 400px) {
    //   font-size: 0.9rem;
    // }
    &.text_vid_4 {
      font-size: 0.94rem;
    }
  }
`;

export const Soundbar = styled.div`
  .circle {
    width: 90px;
    height: 90px;
    background: #ffffff;
    border-radius: 999px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
  }

  .line {
    width: 200%;
    position: absolute;
    stroke: #c4c4c4;
    display: block;
  }

  .wave {
    position: absolute;
    width: 200%;
    /* left: -50px; */
    stroke: #c4c4c4;
    animation: wave linear 3s infinite;
    transition: cubic-bezier(0.895, 0.03, 0.685, 0.22);
  }

  @keyframes wave {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-21%);
    }
  }

  .hidden {
    display: none;
  }
`;
