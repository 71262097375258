import React, { useRef, useState } from 'react';
import Jumbotron from '../../Components/jumbotron/index';
import { useGlobalStateContext } from '../../context/globalContext';
// import './index.scss';
import './index.css';

export function JumbotronContainer() {

  const [toggle, setToggle] = useState(false)
  const ripple = useRef(null);


  setTimeout(function(){
    // toggle && console.log('Delayed True')
    toggle && setToggle(!toggle)
}, 500);

  const { currentTheme } = useGlobalStateContext();
  return (
    <div>
      <div className='Mobile-Container'>
        {/* Jumbotron 1 */}
        <div className='Jumbo'>
          <div className='meaning1'>
            <div>
              <div>
                <Jumbotron.Text>
                  <p>
                    <span>W</span>hat is Ayusha Opus and why have I chosen this
                    name to represent what I do?
                  </p>
                  <p>
                    This name is a compilation of some very special words and
                    their meanings are synonymous with the art that I produce.
                  </p>
                  <p>
                    <span>A</span>yusha means “Age, duration of life and
                    lineage” Its origin is Sanskrit; however in Arabic Ayusha
                    means “one who lives well, one who has a good life, bestowed
                    prosperity and hope”
                  </p>
                  <p>
                    <span>O</span>pus means “artistic work, especially one on a
                    large scale”
                  </p>
                </Jumbotron.Text>
              </div>
              <div>
                {/* <br /><br /> */}
                {/* <Jumbotron.Image
                  className='AO_LOGO_MEANING'
                  src={
                    currentTheme === 'dark'
                      ? require('../../assets/img/meaning/AO_Logo_on_black.png')
                        .default
                      : require('../../assets/img/meaning/AO_Logo_on_white.png')
                        .default
                  }
                  alt=''
                /> */}
                <div class={`hover15 columnss`}>
                  <div>
                    <figure  ref={ripple}
                       onClick={() => {
                        setToggle(!toggle)
                       }}
                      className={`${toggle && 'rippleForMobile'}`}
                    >
                      <img
                        className='AO_LOGO_MEANING'
                        src={
                          currentTheme === 'dark'
                            // ? require('../../assets/img/meaning/AO_Logo_on_black.png').default
                            ? 'https://ayushaopus.eu-central-1.linodeobjects.com/209167.d5800390.png'
                            // : require('../../assets/img/meaning/AO_Logo_on_white.png').default
                            : 'https://ayushaopus.eu-central-1.linodeobjects.com/571096.2a5ee135.png'
                        }
                        alt=''
                        onContextMenu={(e) => e.preventDefault()}
                      />
                    </figure>
                  </div>
                  <div className='dontshow'>
                    <figure>
                      <img
                        className='AO_LOGO_MEANING'
                        src={
                          currentTheme === 'dark'
                            // ? require('../../assets/img/meaning/AO_Logo_on_black.png').default
                            ? 'https://ayushaopus.eu-central-1.linodeobjects.com/209167.d5800390.png'
                            // : require('../../assets/img/meaning/AO_Logo_on_white.png').default
                            : 'https://ayushaopus.eu-central-1.linodeobjects.com/571096.2a5ee135.png'
                        }
                        alt=''
                         onContextMenu={(e) => e.preventDefault()}
                      />
                    </figure>
                  </div>
                  <div className='dontshow'>
                    <figure>
                      <img
                        className='AO_LOGO_MEANING'
                        src={
                          currentTheme === 'dark'
                            // ? require('../../assets/img/meaning/AO_Logo_on_black.png').default
                            ? 'https://ayushaopus.eu-central-1.linodeobjects.com/209167.d5800390.png'
                            // : require('../../assets/img/meaning/AO_Logo_on_white.png').default
                            : 'https://ayushaopus.eu-central-1.linodeobjects.com/571096.2a5ee135.png'
                        }
                        alt=''
                         onContextMenu={(e) => e.preventDefault()}
                      />
                    </figure>
                  </div>
                </div>
                {/* <br /><br />    <br />    <br /> */}
                {/* <div className='overlay'></div> */}
              </div>
            </div>
          </div>

          {/* Jumbotron 2 */}
          <div className='meaning2'>
            <div>
              <div>
                <Jumbotron.Text>
                  <p>
                    I’ve chosen to put these very special words, their origins
                    and their meanings together in order to signify the artistic
                    grandeur of what I have created, knowing that it will stand
                    as a symbol of your success and that it will live on within
                    your lineage or a bestowed precious gift to whomever you
                    choose.
                  </p>
                  <p>
                    The essence of a beautiful union is in its’ entirety
                    everything that we do ~ <span>A</span>lpha and{' '}
                    <span>O</span>mega ~ from the beginning till the end.
                  </p>
                </Jumbotron.Text>
              </div>
              <div>
                <Jumbotron.Image
                  // src='https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/meaning1.jpg'
                  src = 'https://ayushaopus.eu-central-1.linodeobjects.com/851907.b0baae57.jpg'
                  // src={require('../../assets/img/meaning/meaning1.jpg').default}
                  alt=''
                  onContextMenu={(e) => e.preventDefault()}
                />
                <div className='overlay'></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='Desktop-Container'>
        {/* Jumbotron 1 */}
        <div className='Jumbo'>
          {/* Jumbotron 1 */}
          <Jumbotron.Container className='meaning1'>
            <Jumbotron direction='row'>
              <Jumbotron.Pane>
                <Jumbotron.Text>
                  <p>
                    <span>W</span>hat is Ayusha Opus and why have I chosen this
                    name to represent what I do?
                  </p>
                  <p>
                    This name is a compilation of some very special words and
                    their meanings are synonymous with the art that I produce.
                  </p>
                  <p>
                    <span>A</span>yusha means “Age, duration of life and
                    lineage” Its origin is Sanskrit; however in Arabic Ayusha
                    means “one who lives well, one who has a good life, bestowed
                    prosperity and hope”
                  </p>
                  <p>
                    <span>O</span>pus means “artistic work, especially one on a
                    large scale”
                  </p>
                </Jumbotron.Text>
              </Jumbotron.Pane>
              <Jumbotron.Pane>
                <div className='full-landing-image'></div>
                {/* <Jumbotron.Image
                  className='AO_LOGO_MEANING full-landing-image'
                  src={
                    currentTheme === 'dark'
                      ? require('../../assets/img/meaning/AO_Logo_on_black.png')
                          .default
                      : require('../../assets/img/meaning/AO_Logo_on_white.png')
                          .default
                  }
                  alt=''
                /> */}

                <div class='hover15 columnss'>
                  <div>
                    <figure>
                      <img
                        className='AO_LOGO_MEANING'
                        src={
                          currentTheme === 'dark'
                            // ? require('../../assets/img/meaning/AO_Logo_on_black.png').default
                            ? 'https://ayushaopus.eu-central-1.linodeobjects.com/209167.d5800390.png'
                            // : require('../../assets/img/meaning/AO_Logo_on_white.png').default
                            : 'https://ayushaopus.eu-central-1.linodeobjects.com/571096.2a5ee135.png'
                        }
                        alt=''
                        onContextMenu={(e) => e.preventDefault()}
                      /> 
                    </figure>
                  </div>
                  <div className='dontshow'>
                    <figure>
                      <img
                        className='AO_LOGO_MEANING'
                        src={
                          currentTheme === 'dark'
                            // ? require('../../assets/img/meaning/AO_Logo_on_black.png').default
                            ? 'https://ayushaopus.eu-central-1.linodeobjects.com/209167.d5800390.png'
                            // : require('../../assets/img/meaning/AO_Logo_on_white.png').default
                            : 'https://ayushaopus.eu-central-1.linodeobjects.com/571096.2a5ee135.png'
                        }
                        alt=''
                        onContextMenu={(e) => e.preventDefault()}
                      /> 
                    </figure>
                  </div>
                  <div className='dontshow'>
                    <figure>
                      <img
                        className='AO_LOGO_MEANING'
                        src={
                          currentTheme === 'dark'
                            // ? require('../../assets/img/meaning/AO_Logo_on_black.png').default
                            ? 'https://ayushaopus.eu-central-1.linodeobjects.com/209167.d5800390.png'
                            // : require('../../assets/img/meaning/AO_Logo_on_white.png').default
                            : 'https://ayushaopus.eu-central-1.linodeobjects.com/571096.2a5ee135.png'
                        }
                        alt=''
                        onContextMenu={(e) => e.preventDefault()}
                      /> 
                    </figure>
                  </div>
                </div>
                {/* <div className='overlay'></div> */}
              </Jumbotron.Pane>
            </Jumbotron>
          </Jumbotron.Container>

          {/* Jumbotron 2 */}
          <Jumbotron.Container className='meaning2'>
            <Jumbotron direction='row-reverse'>
              <Jumbotron.Pane>
                <Jumbotron.Text>
                  <p>
                    I’ve chosen to put these very special words, their origins
                    and their meanings together in order to signify the artistic
                    grandeur of what I have created, knowing that it will stand
                    as a symbol of your success and that it will live on within
                    your lineage or a bestowed precious gift to whomever you
                    choose.
                  </p>
                  <p>
                    The essence of a beautiful union is in its’ entirety
                    everything that we do ~ <span>A</span>lpha and{' '}
                    <span>O</span>mega ~ from the beginning till the end.
                  </p>
                </Jumbotron.Text>
              </Jumbotron.Pane>
              <Jumbotron.Pane>
                <Jumbotron.Image
                  // src='https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/meaning1.jpg'
                  src = 'https://ayushaopus.eu-central-1.linodeobjects.com/851907.b0baae57.jpg'
                  // src={require('../../assets/img/meaning/meaning1.jpg').default}
                  alt=''
                  onContextMenu={(e) => e.preventDefault()}
                />
                <div className='overlay'></div>
              </Jumbotron.Pane>
            </Jumbotron>
          </Jumbotron.Container>
        
        </div>
      </div>
    </div>
  );
}
