import firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/database';
import 'firebase/analytics'

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const analytics = firebase.analytics();
// firebase.analytics.logEvent('notification_received');

try {
  const firebaseConfig = {
    apiKey: 'AIzaSyDj9eyntyzwAaPupPw8MXMvtNqmLX9IzEA',
    authDomain: 'ayusha-opus.firebaseapp.com',
    projectId: 'ayusha-opus',
    messagingSenderId: '673511378763',
    appId: '1:673511378763:web:9e1c91b98cbde9558ae2ce',
    measurementId: 'G-ZX3HDFYBBT',
  };
  // Initialize Firebase
  // firebase.analytics();
  firebase.initializeApp(firebaseConfig);
  // firebase.firestore().settings({timesstampsInSnapshots:true})
} catch (error) {
  if (!/already exists/.test(error.message)) {
    console.log(`Firebase didnt initialize correctly: ${error.message}`);
  }
}

const storage = firebase.storage();
const analytics = firebase.analytics();

export { analytics, storage, firebase as default };
