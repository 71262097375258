import React from 'react';
import Jumbotron from '../../Components/jumbotron/index';

export function JumbotronContainer() {
  return (
    <>
      <div className="Desktop-Container">
        <div className="Jumbo">
          {/* Jumbotron 1 */}
          <Jumbotron.Container className='metal1'>
            <Jumbotron direction='row'>
              <Jumbotron.Pane>
                <Jumbotron.Text>
                  <p>
                    My <span>C</span>anvas so to speak is made of metal specifically
                    steel, copper, brass, bronze & aluminum~! Exotic intricate
                    designs encompassing a range in height from 5 feet (154.4 cm) to
                    10 + feet (304.8 + cm) @ 1 inch thickness (2.54 cm).
                  </p>
                  <p>The largest and most grand carvings in the world!</p>
                </Jumbotron.Text>
              </Jumbotron.Pane>
              <Jumbotron.Pane>
                <Jumbotron.Image
                  // src={require('../../assets/img/metal/metal1.jpg').default}
                  src = 'https://ayushaopus.eu-central-1.linodeobjects.com/681421.d5b4a9bd.jpg'
                  // src='https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/metal1.jpg'
                  alt=''
                  onContextMenu={(e) => e.preventDefault()}
                />
                <div className='overlay'></div>
              </Jumbotron.Pane>
            </Jumbotron>
          </Jumbotron.Container>

          {/* Jumbotron 2 */}
          <Jumbotron.Container className='metal2'>
            <Jumbotron direction='row-reverse'>
              <Jumbotron.Pane>
                <Jumbotron.Text>
                  <p>
                    Our extravagant patina finishes will also add a very
                    personalized signature and taste if you wish; however, I prefer
                    the simplicity and sheer industrial raw energy of steel.
                  </p>
                </Jumbotron.Text>
              </Jumbotron.Pane>
              <Jumbotron.Pane>
                <Jumbotron.Image
                  // src={require('../../assets/img/metal/metal2.jpg').default}
                  src = 'https://ayushaopus.eu-central-1.linodeobjects.com/284170.58016c361.jpg'
                  // src='https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/metal2.jpg'
                  alt=''
                  onContextMenu={(e) => e.preventDefault()}
                />
                <div className='overlay'></div>
              </Jumbotron.Pane>
            </Jumbotron>
          </Jumbotron.Container>

          {/* Jumbotron 3 */}
          <Jumbotron.Container className='metal3'>
            <Jumbotron direction='row'>
              <Jumbotron.Pane>
                <Jumbotron.Text>
                  <p>
                    <span>S</span>tunning and one of a kind ~! …is what I specialize
                    in creating.
                  </p>
                </Jumbotron.Text>
              </Jumbotron.Pane>
            </Jumbotron>
          </Jumbotron.Container>
        </div>
      </div>

      <div className="Mobile-Container">
        <div className="Jumbo">
          {/* Jumbotron 1 */}
          <div className='metal1'>
            <div>
              <div>
                <Jumbotron.Text>
                  <p>
                    My <span>C</span>anvas so to speak is made of metal specifically
                    steel, copper, brass, bronze & aluminum~! Exotic intricate
                    designs encompassing a range in height from 5 feet (154.4 cm) to
                    10 + feet (304.8 + cm) @ 1 inch thickness (2.54 cm).
                  </p>
                  <p>The largest and most grand carvings in the world!</p>
                </Jumbotron.Text>
              </div>
              <div>
                <Jumbotron.Image
                  // src={require('../../assets/img/metal/metal1.jpg').default}
                  src = 'https://ayushaopus.eu-central-1.linodeobjects.com/681421.d5b4a9bd.jpg'
                  // src='https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/metal1.jpg'
                  alt=''
                  onContextMenu={(e) => e.preventDefault()}
                />
                <div className='overlay'></div>
              </div>
            </div>
          </div>

          {/* Jumbotron 2 */}
          <div className='metal2'>
            <div>
              <div>
                <Jumbotron.Text>
                  <p>
                    Our extravagant patina finishes will also add a very
                    personalized signature and taste if you wish; however, I prefer
                    the simplicity and sheer industrial raw energy of steel.
                  </p>
                </Jumbotron.Text>
              </div>
              <div>
                <Jumbotron.Image
                  // src={require('../../assets/img/metal/metal2.jpg').default}
                  src = 'https://ayushaopus.eu-central-1.linodeobjects.com/284170.58016c361.jpg'
                  // src='https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/metal2.jpg'
                  alt=''
                  onContextMenu={(e) => e.preventDefault()}
                />
                <div className='overlay'></div>
              </div>
            </div>
          </div>

          {/* Jumbotron 3 */}
          <div className='metal3'>
            <div>
              <div>
                <Jumbotron.Text>
                  <p>
                    <span>S</span>tunning and one of a kind ~! …is what I specialize
                    in creating.
                  </p>
                </Jumbotron.Text>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
