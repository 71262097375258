import React, { useEffect, useRef } from 'react';
import { useGlobalStateContext } from '../context/globalContext';
import gsap, { TimelineLite, TweenMax, Expo } from 'gsap';
import $ from 'jquery';

// import Image1 from '../assets/img/artist/artist1.jpg';

import {
  NavigationItem,
  NavigationList,
  NavigationWapper,
  ProjecPreview,
  ProjectOverlay,
  ProjectPreviewWrapper,
  ProgressBar,
} from '../styles/sencesStyles';
import { Link } from 'react-router-dom';
import {
  HomeAccordian,
  CopyrightAccordion,
} from '../Components/Homepage/HomeAbout';
import { analytics } from '../firebase/index';

const Scenes = ({ onCursor }) => {
  useEffect(() => {
    analytics.logEvent('Scenes_Page_visited');
    let scrollPercent;
    $(window).scroll(function () {
      var scroll = $(window).scrollTop(),
        dh = $(document).height(),
        wh = $(window).height();
      scrollPercent = (scroll / (dh - wh)) * 100;
      $('.progressbar').css('height', scrollPercent + '%');
    });
  }, []);

  useEffect(() => {
    TweenMax.set(projectPreview.current, { width: 0 });
    let navItems = gsap.utils.toArray('.item');
    let tl = new TimelineLite();
    navItems.forEach((item) => {
      item.addEventListener('mouseover', (e) => {
        tl.to(projectPreview.current, 1, {
          // width: '200%',
          width: '190%',
          background: `url(${item.dataset.img}) no-repeat 50% 50%`,
          backgroundSize: 'cover',
          ease: Expo.easeInOut,
        });
      });
      item.addEventListener('mouseout', (e) => {
        let tl2 = new TimelineLite();
        tl2.to(projectPreview.current, 0.5, {
          width: '0px',
          ease: Expo.easeInOut,
        });
      });
    });
    /*var tl = new TimelineLite();
        navItem1.current.addEventListener('mouseenter', e => {
            tl.to($(projectPreview.current), 1, {
                width: '600px',
                ease: Expo.easeInOut
            });
        })
        navItem1.current.addEventListener('mouseleave', e => {
            tl.to($(projectPreview.current), 0.5, {
                width: '0px',
                ease: Expo.easeInOut
            });
        })*/
    // tl.to(projectPreview.current)
  }, []);

  const projectPreview = useRef(null);
  const navList = useRef();
  const { currentTheme } = useGlobalStateContext();

  const content = [
    {
      label: 'Foyer',
      timeStamp: '0',
      // img: 'https://ayushaopus.eu-central-1.linodeobjects.com/981270.f05bef81a.jpg',
      img: require('../assets/img/scenes/foyer.jpg'),
    },
    {
      label: 'Master Bedroom',
      timeStamp: '155',
      // img: 'https://ayushaopus.eu-central-1.linodeobjects.com/314070.db4ccab9.jpg',
      img: require('../assets/img/scenes/masterBedroom.jpg'),
    },
    {
      label: 'Bathroom',
      timeStamp: '203',
      // img: 'https://ayushaopus.eu-central-1.linodeobjects.com/471989.6acf3934.jpg',
      img: require('../assets/img/scenes/bathroom.jpg'),
    },
    {
      label: 'Guest Bedroom',
      timeStamp: '228',
      // img: 'https://ayushaopus.eu-central-1.linodeobjects.com/914853.7da9cbbb.jpg',
      img: require('../assets/img/scenes/guestBedroom.jpg'),
    },
    {
      label: 'Elevator',
      timeStamp: '260',
      // img: 'https://ayushaopus.eu-central-1.linodeobjects.com/490628.162876c1.jpg',
      img: require('../assets/img//scenes/elevator.jpg'),
    },
    {
      label: 'Prayer Room',
      timeStamp: '299',
      // img: 'https://ayushaopus.eu-central-1.linodeobjects.com/168096.b0baae57.jpg',
      img: require('../assets/img/scenes/prayerRoom.jpg'),
    },
    {
      label: 'Majlis',
      timeStamp: '341',
      // img: 'https://ayushaopus.eu-central-1.linodeobjects.com/529081.d65be5f0.jpg'
      img: require('../assets/img/scenes/majlis.jpg'),
    },
    {
      label: 'Courtyard',
      timeStamp: '450',
      // img: 'https://ayushaopus.eu-central-1.linodeobjects.com/612573.1c2a2459.jpg'
      img: require('../assets/img/scenes/courtyard.jpg'),
    },
    {
      label: 'Airplane',
      timeStamp: '651',
      // img: 'https://ayushaopus.eu-central-1.linodeobjects.com/609148.8f7e8571.jpg'
      img: require('../assets/img/scenes/airplane.jpg'),
    },
    {
      label: 'Living & Dining room',
      timeStamp: '711',
      // img: 'https://ayushaopus.eu-central-1.linodeobjects.com/370158.c915da77.jpg'
      img: require('../assets/img/scenes/livingDiningRoom.jpg'),
    },
    {
      label: 'Yacht',
      timeStamp: '822',
      // img: 'https://ayushaopus.eu-central-1.linodeobjects.com/329064.1137f30d.jpg'
      img: require('../assets/img/scenes/yacht.jpg'),
    },
    {
      label: 'Garage',
      timeStamp: '945',
      // img: 'https://ayushaopus.eu-central-1.linodeobjects.com/183092.47f4eac3.mp4'
      img: require('../assets/img/scenes/garage.jpg'),
    },
  ];
  return (
    <>
      <NavigationWapper>
        <ProjectPreviewWrapper>
          <div
            className={'project_preview'}
            style={{
              // position: 'fixed',
              // top: '12%',
              // left: '50%',
              // width: '600px',
              // height: '400px',
              // overflow: 'hidden',
              background: `url(https://ayushaopus.eu-central-1.linodeobjects.com/981270.f05bef81a.jpg) no-repeat 50% 50%`,
              // background: `url(${Image1}) no-repeat 50% 50%`,
            }}
            ref={projectPreview}
          ></div>
        </ProjectPreviewWrapper>
        <NavigationList ref={navList} currentTheme={currentTheme}>
          <ProjectOverlay />
          {content.map((item) => {
            return (
              <NavigationItem
                data-img={item.img}
                className={'item'}
                key={item.label}
              >
                <Link
                  to={`/main-video?t=${item.timeStamp}`}
                  className='navigation-link navigation-link-2'
                >
                  <span data-text={item.label}>{item.label}</span>
                </Link>
              </NavigationItem>
            );
          })}
          {/*<NavigationItem ref={navItem1}>
                        <a data-img={'img-1.jpg'} className="navigation-link navigation-link-1" href="#">
                            <span data-text="Mouthwash">Mouthwash</span>
                        </a>
                    </NavigationItem>
                    <ProjectOverlay/>
                    <NavigationItem ref={navItem2}>
                        <a data-img={'img-2.jpg'} className="navigation-link navigation-link-2" href="#">
                            <span data-text="Offbeat">Offbeat</span>
                        </a>
                    </NavigationItem>
                    <NavigationItem ref={navItem3}>
                        <a data-img={'img-3.jpg'} className="navigation-link navigation-link-3" href="#">
                            <span data-text="Expansions">Expansions</span>
                        </a>
                    </NavigationItem>
                    <NavigationItem ref={navItem4}>
                        <a data-img={'img-4.jpg'} className="navigation-link navigation-link-4" href="#">
                            <span data-text="Canals">Canals</span>
                        </a>
                    </NavigationItem>
                    <NavigationItem>
                        <a data-img={'img-5.jpg'} className="navigation-link navigation-link-5" href="#">
                            <span data-text="Asterisks">Asterisks</span>
                        </a>
                    </NavigationItem>
                    <NavigationItem>
                        <a data-img={'img-6.jpg'} className="navigation-link navigation-link-6" href="#">
                            <span data-text="Charger">Charger</span>
                        </a>
                    </NavigationItem>
                    <NavigationItem>
                        <a data-img={'img-7.jpg'} className="navigation-link navigation-link-7" href="#">
                            <span data-text="Unclocked">Unclocked</span>
                        </a>
                    </NavigationItem>
                    <NavigationItem>
                        <a data-img={'img-8.jpg'} className="navigation-link navigation-link-8" href="#">
                            <span data-text="Craft">Craft</span>
                        </a>
                    </NavigationItem>*/}
        </NavigationList>
      </NavigationWapper>
      <HomeAccordian onCursor={onCursor} />
      {/* <CopyrightAccordion onCursor={onCursor} /> */}
      {/* <div
        style={{
          position: 'fixed',
          top: '12%',
          left: '5.2%',
          background: 'rgba(234, 231, 231)',
          width: '1px',
          zIndex: ' 1',
          maxHeight: '74%',
        }}
        className='progressbar'
      /> */}
      <ProgressBar className='progressbar' />

      {/* <script>
                $(document).ready(function() {
                TweenMax.set(".project-preview", {width: 0});

                var tl = new TimelineLite();

                $(document)
                .on("mouseover", ".navigation-item", function(evt) {
                tl = new TimelineLite();
                tl.to($(".project-preview"), 1, {
                width: "600px",
                ease: Expo.easeInOut
            });
            })
                .on("mouseout", ".navigation-item", function(evt) {
                tl = new TimelineLite();
                tl.to($(".project-preview"), 0.5, {
                width: 0,
                ease: Expo.easeInOut
            });
            });
            });

                $(".navigation-link-1").hover(function() {
                $(".project-preview").css({"background-image": "url(img-1.jpg)"});
            });

                $(".navigation-link-2").hover(function() {
                $(".project-preview").css({"background-image": "url(img-2.jpg)"});
            });

                $(".navigation-link-3").hover(function() {
                $(".project-preview").css({"background-image": "url(img-3.jpg)"});
            });

                $(".navigation-link-4").hover(function() {
                $(".project-preview").css({"background-image": "url(img-4.jpg)"});
            });

                $(".navigation-link-5").hover(function() {
                $(".project-preview").css({"background-image": "url(img-5.jpg)"});
            });

                $(".navigation-link-6").hover(function() {
                $(".project-preview").css({"background-image": "url(img-6.jpg)"});
            });

                $(".navigation-link-7").hover(function() {
                $(".project-preview").css({"background-image": "url(img-7.jpg)"});
            });

                $(".navigation-link-8").hover(function() {
                $(".project-preview").css({"background-image": "url(img-8.jpg)"});
            });

                $(window).scroll(function() {
                var scroll = $(window).scrollTop(),
                dh = $(document).height(),
                wh = $(window).height();
                scrollPercent = (scroll / (dh - wh)) * 100;
                $(".progressbar").css("height", scrollPercent + "%");
            });
            </script>*/}
    </>
  );
};

export default Scenes;
