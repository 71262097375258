import React from 'react';

export const Instagram = () => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 21 21'>
    <defs>
      <linearGradient id='myGradient' gradientTransform='rotate(100)'>
        <stop offset='20%' stop-color='rgb(156, 25, 47)' />
        <stop offset='35%' stop-color='rgb(235, 0, 42)' />
        <stop offset='50%' stop-color='rgb(170, 0, 52)' />
        <stop offset='75%' stop-color='rgb(235, 0, 42)' />
        <stop offset='100%' stop-color='rgb(177, 33, 43' />
      </linearGradient>
    </defs>
    <g fill='url(#myGradient)' fillRule='evenodd'>
      <path d='M10.5 0C7.648 0 7.29.012 6.17.063 5.054.114 4.29.292 3.623.551a5.146 5.146 0 00-1.86 1.211 5.147 5.147 0 00-1.21 1.86C.291 4.29.113 5.053.062 6.171.012 7.29 0 7.648 0 10.5c0 2.852.012 3.21.063 4.33.051 1.117.229 1.88.488 2.548.269.69.628 1.276 1.211 1.86.584.583 1.17.942 1.86 1.21.668.26 1.431.438 2.549.489 1.12.05 1.477.063 4.329.063 2.852 0 3.21-.012 4.33-.063 1.117-.051 1.88-.229 2.548-.488a5.146 5.146 0 001.86-1.211 5.147 5.147 0 001.21-1.86c.26-.668.438-1.431.489-2.549.051-1.12.063-1.477.063-4.329 0-2.852-.012-3.21-.063-4.33-.051-1.117-.229-1.88-.488-2.548a5.147 5.147 0 00-1.211-1.86 5.146 5.146 0 00-1.86-1.21c-.668-.26-1.431-.438-2.549-.489C13.71.012 13.352 0 10.5 0m0 1.892c2.804 0 3.136.01 4.243.061 1.024.047 1.58.218 1.95.362.49.19.84.418 1.207.785.367.368.595.717.785 1.207.144.37.315.926.362 1.95.05 1.107.061 1.44.061 4.243 0 2.804-.01 3.136-.061 4.243-.047 1.024-.218 1.58-.362 1.95-.19.49-.418.84-.785 1.207a3.252 3.252 0 01-1.207.785c-.37.144-.926.315-1.95.362-1.107.05-1.44.061-4.243.061-2.804 0-3.136-.01-4.243-.061-1.024-.047-1.58-.218-1.95-.362-.49-.19-.84-.418-1.207-.785a3.254 3.254 0 01-.785-1.207c-.144-.37-.315-.926-.362-1.95-.05-1.107-.061-1.44-.061-4.243 0-2.804.01-3.136.061-4.243.047-1.024.218-1.58.362-1.95.19-.49.418-.84.785-1.207a3.253 3.253 0 011.207-.785c.37-.144.926-.315 1.95-.362 1.107-.05 1.44-.061 4.243-.061'></path>
      <path d='M10.5 14.07a3.57 3.57 0 110-7.14 3.57 3.57 0 010 7.14m0-9.07a5.5 5.5 0 100 11 5.5 5.5 0 000-11'></path>
      <path d='M17 5a1 1 0 11-2 0 1 1 0 012 0'></path>
    </g>
  </svg>
);
export const Facebook = () => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 24'>
    <defs>
      <linearGradient id='myGradient' gradientTransform='rotate(90)'>
        <stop offset='20%' stop-color='rgb(156, 25, 47)' />
        <stop offset='35%' stop-color='rgb(235, 0, 42)' />
        <stop offset='50%' stop-color='rgb(170, 0, 52)' />
        <stop offset='75%' stop-color='rgb(235, 0, 42)' />
        <stop offset='100%' stop-color='rgb(177, 33, 43' />
      </linearGradient>
    </defs>
    <path
      d='M3.625 1.236C4.544.346 5.85.044 7.085.006 8.387-.004 9.691.003 10.995.004c.005 1.4.006 2.802 0 4.203-.843-.001-1.687.002-2.53-.001-.534-.034-1.083.378-1.18.918-.013.937-.004 1.875-.004 2.813 1.236.005 2.472-.002 3.708.003a53.297 53.297 0 01-.45 4.05c-1.091.011-2.183 0-3.275.007-.01 4 .005 8-.008 12-1.622.006-3.244-.004-4.866.004-.03-4 .002-8.003-.017-12.004-.791-.008-1.583.006-2.374-.007.003-1.344.001-2.689.001-4.033.791-.01 1.582.002 2.373-.006.024-1.307-.024-2.616.025-3.922.08-1.035.466-2.077 1.226-2.792z'
      // fill='#eb002a'
      fill='url(#myGradient)'
      fillRule='nonzero'
    ></path>
  </svg>
);

export const Twitter = () => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
    <defs>
      <linearGradient id='myGradient' gradientTransform='rotate(100)'>
        <stop offset='20%' stop-color='rgb(156, 25, 47)' />
        <stop offset='35%' stop-color='rgb(235, 0, 42)' />
        <stop offset='50%' stop-color='rgb(170, 0, 52)' />
        <stop offset='75%' stop-color='rgb(235, 0, 42)' />
        <stop offset='100%' stop-color='rgb(177, 33, 43' />
      </linearGradient>
    </defs>
    <path
      d='M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z'
      fill='url(#myGradient)'
      fillRule='evenodd'
    />
  </svg>
);

export const Pinterest = () => (
  <svg version="1.1"xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	viewBox="0 0 511.998 511.998" xmlSpace="preserve">
    <defs>
      <linearGradient id='myGradient' gradientTransform='rotate(100)'>
        <stop offset='20%' stop-color='rgb(156, 25, 47)' />
        <stop offset='35%' stop-color='rgb(235, 0, 42)' />
        <stop offset='50%' stop-color='rgb(170, 0, 52)' />
        <stop offset='75%' stop-color='rgb(235, 0, 42)' />
        <stop offset='100%' stop-color='rgb(177, 33, 43' />
      </linearGradient>
    </defs>
    <path
    d="M405.017,52.467C369.774,18.634,321.001,0,267.684,0C186.24,0,136.148,33.385,108.468,61.39
    c-34.114,34.513-53.675,80.34-53.675,125.732c0,56.993,23.839,100.737,63.76,117.011c2.68,1.098,5.377,1.651,8.021,1.651
    c8.422,0,15.095-5.511,17.407-14.35c1.348-5.071,4.47-17.582,5.828-23.013c2.906-10.725,0.558-15.884-5.78-23.353
    c-11.546-13.662-16.923-29.817-16.923-50.842c0-62.451,46.502-128.823,132.689-128.823c68.386,0,110.866,38.868,110.866,101.434
    c0,39.482-8.504,76.046-23.951,102.961c-10.734,18.702-29.609,40.995-58.585,40.995c-12.53,0-23.786-5.147-30.888-14.121
    c-6.709-8.483-8.921-19.441-6.222-30.862c3.048-12.904,7.205-26.364,11.228-39.376c7.337-23.766,14.273-46.213,14.273-64.122
    c0-30.632-18.832-51.215-46.857-51.215c-35.616,0-63.519,36.174-63.519,82.354c0,22.648,6.019,39.588,8.744,46.092
    c-4.487,19.01-31.153,132.03-36.211,153.342c-2.925,12.441-20.543,110.705,8.618,118.54c32.764,8.803,62.051-86.899,65.032-97.713
    c2.416-8.795,10.869-42.052,16.049-62.495c15.817,15.235,41.284,25.535,66.064,25.535c46.715,0,88.727-21.022,118.298-59.189
    c28.679-37.02,44.474-88.618,44.474-145.282C457.206,127.983,438.182,84.311,405.017,52.467z"
    fill='url(#myGradient)'
    fillRule='evenodd'
    />
</svg>

);

export const Vimeo = () => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 21 18'>
    <path
      d='M20.99 4.164c-.094 2.026-1.521 4.8-4.283 8.32C13.852 16.162 11.436 18 9.461 18c-1.225 0-2.261-1.12-3.107-3.359l-1.695-6.16C4.03 6.244 3.357 5.123 2.636 5.123c-.158 0-.707.328-1.649.98L0 4.842a259.826 259.826 0 003.064-2.709C4.446.95 5.485.328 6.176.265c1.634-.156 2.64.951 3.018 3.32.408 2.557.69 4.147.849 4.77.472 2.121.99 3.18 1.556 3.18.439 0 1.1-.687 1.98-2.064.879-1.376 1.35-2.422 1.413-3.142.126-1.187-.345-1.782-1.413-1.782-.503 0-1.021.114-1.554.34 1.032-3.349 3.003-4.976 5.914-4.883 2.158.063 3.175 1.45 3.05 4.16'
      fill='#eb002a'
      fillRule='evenodd'
    ></path>
  </svg>
);

export const Move = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='40'
    height='40'
    viewBox='0 0 24 24'
    fill='none'
    color='#eb002a'
    stroke='currentColor'
    strokeWidth='2'
    strokeLinecap='round'
    strokeLinejoin='round'
    className='feather feather-move'
    style={{ transform: 'translate(35%, 35%', cursor: 'pointer' }}
  >
    <polyline points='5 9 2 12 5 15'></polyline>
    <polyline points='9 5 12 2 15 5'></polyline>
    <polyline points='15 19 12 22 9 19'></polyline>
    <polyline points='19 9 22 12 19 15'></polyline>
    <line x1='2' y1='12' x2='22' y2='12'></line>
    <line x1='12' y1='2' x2='12' y2='22'></line>
  </svg>
);

export const Wave = () => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1440 320'>
    <path
      fill='#00000'
      fillOpacity='1'
      d='M0,192L17.1,208C34.3,224,69,256,103,277.3C137.1,299,171,309,206,288C240,267,274,213,309,170.7C342.9,128,377,96,411,90.7C445.7,85,480,107,514,144C548.6,181,583,235,617,224C651.4,213,686,139,720,138.7C754.3,139,789,213,823,224C857.1,235,891,181,926,176C960,171,994,213,1029,218.7C1062.9,224,1097,192,1131,149.3C1165.7,107,1200,53,1234,48C1268.6,43,1303,85,1337,106.7C1371.4,128,1406,128,1423,128L1440,128L1440,320L1422.9,320C1405.7,320,1371,320,1337,320C1302.9,320,1269,320,1234,320C1200,320,1166,320,1131,320C1097.1,320,1063,320,1029,320C994.3,320,960,320,926,320C891.4,320,857,320,823,320C788.6,320,754,320,720,320C685.7,320,651,320,617,320C582.9,320,549,320,514,320C480,320,446,320,411,320C377.1,320,343,320,309,320C274.3,320,240,320,206,320C171.4,320,137,320,103,320C68.6,320,34,320,17,320L0,320Z'
    ></path>
  </svg>
);

export const Trademark = () => (

<svg style={{fill: '#fff', width: '16px', marginRight: '5px'}} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 122.88 122.88" xmlSpace="preserve">
<defs>
      <linearGradient id='myGradient' gradientTransform='rotate(100)'>
        <stop offset='20%' stop-color='rgb(156, 25, 47)' />
        <stop offset='35%' stop-color='rgb(235, 0, 42)' />
        <stop offset='50%' stop-color='rgb(170, 0, 52)' />
        <stop offset='75%' stop-color='rgb(235, 0, 42)' />
        <stop offset='100%' stop-color='rgb(177, 33, 43' />
      </linearGradient>
    </defs>
  <g>
    <path d="M109.72,61.44v-0.01h0.02c0-13.31-5.42-25.38-14.17-34.13c-8.75-8.74-20.81-14.16-34.1-14.16v0.02h-0.03h-0.01v-0.02 c-13.3,0-25.38,5.42-34.13,14.17c-8.74,8.75-14.16,20.81-14.16,34.11h0.02v0.03v0.01h-0.02c0,8.88,2.41,17.22,6.59,24.36 c8.38,14.31,23.92,23.92,41.68,23.93v-0.02h0.03h0.01v0.02c13.31,0,25.38-5.42,34.13-14.17c1.44-1.44,2.78-2.95,4-4.52 C105.93,82.88,109.72,72.6,109.72,61.44L109.72,61.44z M39.91,86.16V35.18h26.26c4.87,0,8.59,0.42,11.16,1.26 c2.58,0.83,4.65,2.37,6.23,4.64c1.58,2.26,2.36,5.01,2.36,8.25c0,2.84-0.61,5.27-1.8,7.32c-1.21,2.05-2.87,3.71-4.98,5 c-1.35,0.81-3.19,1.48-5.53,2c1.88,0.63,3.24,1.26,4.11,1.89c0.57,0.42,1.41,1.31,2.52,2.67c1.1,1.38,1.84,2.42,2.21,3.17 l7.62,14.78H72.26l-8.42-15.59c-1.07-2-2.02-3.32-2.86-3.93c-1.13-0.78-2.42-1.18-3.86-1.18h-1.39v20.69H39.91L39.91,86.16z M55.74,55.83h6.65c0.71,0,2.1-0.23,4.17-0.69c1.04-0.21,1.9-0.75,2.56-1.6c0.65-0.85,0.99-1.84,0.99-2.95 c0-1.65-0.52-2.91-1.57-3.79c-1.05-0.89-3-1.33-5.88-1.33h-6.92V55.83L55.74,55.83z M122.85,61.43h0.03v0.01 c0,14.19-4.82,27.27-12.92,37.68c-1.59,2.04-3.29,3.97-5.08,5.76c-11.1,11.1-26.45,17.97-43.43,17.98v0.03h-0.01h-0.03v-0.03 c-22.59-0.01-42.34-12.22-52.99-30.4c-5.33-9.1-8.39-19.7-8.39-31H0v-0.01v-0.03h0.03C0.03,44.44,6.9,29.09,18,18 C29.09,6.9,44.45,0.03,61.43,0.03V0h0.01h0.03v0.03c16.97,0.01,32.32,6.87,43.41,17.97C115.98,29.09,122.85,44.45,122.85,61.43 L122.85,61.43z"/>
  </g>
</svg>

);
export const CopyrightSVG = () => (
<svg style={{fill: '#fff', width: '16px', marginRight: '5px'}} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 367.467 367.467" xmlSpace="preserve">
     <defs>
      <linearGradient id='myGradient' gradientTransform='rotate(100)'>
        <stop offset='20%' stop-color='rgb(156, 25, 47)' />
        <stop offset='35%' stop-color='rgb(235, 0, 42)' />
        <stop offset='50%' stop-color='rgb(170, 0, 52)' />
        <stop offset='75%' stop-color='rgb(235, 0, 42)' />
        <stop offset='100%' stop-color='rgb(177, 33, 43' />
      </linearGradient>
    </defs>
<g>
	<path d="M183.73,0.018C82.427,0.018,0,82.404,0,183.733c0,101.289,82.427,183.716,183.73,183.716
		c101.315,0,183.737-82.427,183.737-183.716C367.467,82.404,285.045,0.018,183.73,0.018z M183.73,326.518
		c-78.743,0-142.798-64.052-142.798-142.784c0-78.766,64.055-142.817,142.798-142.817c78.752,0,142.807,64.052,142.807,142.817
		C326.536,262.466,262.481,326.518,183.73,326.518z"/>
	<path d="M244.036,217.014c-11.737,20.141-33.562,32.635-56.956,32.635c-36.329,0-65.921-29.585-65.921-65.915
		c0-36.36,29.592-65.955,65.921-65.955c23.395,0,45.219,12.54,56.956,32.641l1.517,2.627h44.28l-2.658-7.129
		c-7.705-20.413-21.225-37.769-39.122-50.157c-17.942-12.42-39.017-19.009-60.973-19.009c-58.981,0-106.946,48.006-106.946,106.982
		c0,58.98,47.965,106.941,106.946,106.941c21.956,0,43.03-6.567,60.973-19.006c17.897-12.391,31.417-29.741,39.122-50.154
		l2.658-7.133h-44.28L244.036,217.014z"/>
</g>
</svg>
);

// 1St Trasemark less bold
{/* <svg width="1024px" style={{fill: '#f52936', width: '30px', height: '30px'}} height="1024px" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" className="icon">
<path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372zm87.5-334.7c34.8-12.8 78.4-49 78.4-119.2 0-71.2-45.5-131.1-144.2-131.1H378c-4.4 0-8 3.6-8 8v410c0 4.4 3.6 8 8 8h54.5c4.4 0 8-3.6 8-8V561.2h88.7l74.6 159.2c1.3 2.8 4.1 4.6 7.2 4.6h62a7.9 7.9 0 0 0 7.1-11.5l-80.6-164.2zM522 505h-81.5V357h83.4c48 0 80.9 25.3 80.9 75.5 0 46.9-29.8 72.5-82.8 72.5z"/>
</svg> */}