import React, { useState } from 'react';
import { Col, Row, FormContainer } from '../styles/formStyles';
import swal from 'sweetalert';

//Importing the email js send
//The Email js implementation
import { send } from 'emailjs-com';
//The Email js implementation

//Importing the styling
// import "./Form.scss";
import './Form.css';

const Form = ({ onCursor }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');

  const [emailStatus, setEmailStatus] = useState(false);

  // function send() {}

  /////////////////////////////////////The Email js implementation/////////////////////////////
  const toSend = {
    from_name: name,
    to_name: 'Ayusha Opus',
    Names: `Name: ${name}`, 
    Emails:`Email: ${email}`,                        
    Phone:`Phone: ${phone}`,    
    Subject:`Subject: ${subject}`,
    Comments:`Message: ${message}`,
    reply_to: `${email}`,
  };

  const sendEmail = (e) => {
    e.preventDefault();
    //Sending the Email
    send(
      'service_f7xhdkq', //Here you will place service id
      'template_wxev42e', //Here is the template ID You have to place
      toSend,
      'user_8pgusKWUmzquzFCOrS4IE' //Here is the user ID you have to place
    )
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        //     // alert("Hello");
        setEmailStatus(true);
        setName('');
        setEmail('');
        setSubject('');
        setPhone('');
        setMessage('');
      })
      .catch((err) => {
        console.log('FAILED...', err);
      });

    //Resetting the fields

    //Resetting the fields
    /////////////////////////////////////The Email js implementation/////////////////////////////
  };
  // const sendEmail = (e) => {

  //   // create a new XMLHttpRequest
  //   let xhr = new XMLHttpRequest();

  //   // get a callback when the server responds
  //   xhr.addEventListener('load', () => {
  //     // update the response state and the step

  //     SetEmailStatus(xhr.responseText);

  //   });
  //   // open the request with the verb and the url
  //   xhr.open('GET', '?sendto=' + 'bilalmohib7896@gmail.com' +
  //     '&name=' + name +
  //     '&email=' + email +
  //     '&subject=' + subject +
  //     '&phone=' + phone +
  //     '&message=' + message);
  //   // send the request
  //   xhr.send();

  //   //Preventing the default behaviour of the form
  //   e.preventDefault();
  // }

  function validateEmail(email) {
    var filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return filter.test(email);
  }
  function validatePhoneNumber(number) {
    var re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;

    return re.test(number);
}

// name.split('').length < 4

  return (
    <>
      {!emailStatus ? (
        <FormContainer>
          <h2>Reach us</h2>
          <Row>
            <Col>
              <input
                type='text'
                placeholder={'Name*'}
                required
                value={name}
                onChange={(e) => setName(e.target.value)}
                name='Name'
                id='Name'
              />
            </Col>
            <Col>
              <input
                type='email'
                placeholder={'Email*'}
                required
                name='email'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                id='email'
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <input
                type='text'
                placeholder={'Subject*'}
                name='subject'
                value={subject}
                minlength="4"
                onChange={(e) => setSubject(e.target.value)}
                id='subject'
              />
            </Col>
            <Col>
              <input
                type='number'
                placeholder={'Phone No. *'}
                name='Phone'
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                id='subject'
              />
            </Col>
          </Row>
          <Row>
            <Col fullWidth>
              <textarea
                placeholder={'Message*'}
                minlength="14"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </Col>
          </Row>
          <Row>
            {name === '' ||
            email === '' || 
            subject.split('').length < 4 ||
            message.split('').length < 14 ||
            !validateEmail(email) ||
            phone === '' ||
            !validatePhoneNumber(phone) === '' ||
            message === '' ||
            subject === '' ? (
              <div style={{ margin: '0 auto' }}>
                <button
                  // onClick={() => alert('Please complete all the fields!')}
                  onClick={() => swal("Ayusha Opus says", "Please complete all the fields!", "error")}
                  onMouseEnter={() => onCursor('hovered')}
                  onMouseLeave={() => onCursor()}
                  // disabled={true}
                >
                  Send
                </button>
              </div>
            ) : (
              <div style={{ margin: '0 auto' }}>
                <button
                  onClick={sendEmail}
                  onMouseEnter={() => onCursor('hovered')}
                  onMouseLeave={() => onCursor()}
                  className='SubmitBtn'
                  type='submit'
                >
                  Send
                </button>
              </div>
            )}
          </Row>
        </FormContainer>
      ) : (
        <FormContainer>
          <h2 className='text-green'>
            <span className='text-white'>✓</span> successfully sent
          </h2>
          {/* <br /><br /> */}
          {/* <h2 className="text-white text-center">we will be in touch</h2> */}
        </FormContainer>
      )}
    </>
  );
};

export default Form;
