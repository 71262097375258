import React, { useEffect, useState } from 'react';
import { Container, Flex } from '../../styles/globalStyle';
import {
  FeaturedContent,
  FeaturedProject,
  FeaturedVideo,
  HomeContentSection,
  HomeFeaturedSection,
} from '../../styles/homeStyles';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { Link } from 'react-router-dom';

// import trailerVideo from '../../assets/video/teaser.mp4';

import { Menu } from '../../styles/headerStyle';
import "../../styles/home.css";

const animateVariants = {
  hover: {
    scale: 1.1,
    textShadow: "0px 0px 8px rgb(255,255,255)",
    transition: {
      yoyo: 10
    }
  }
}

const HomeFeatured = ({ onCursor, toggleMenu, setToggleMenu }) => {
  const [hovered, setHovered] = useState(false);
  const animation = useAnimation();
  const [featuredRef, inVew] = useInView({
    triggerOnce: true,
    rootMargin: '-300px',
  });

  useEffect(() => {
    if (inVew) {
      animation.start('visible');
    }
  }, [inVew, animation]);
  return (
    <HomeFeaturedSection
      ref={featuredRef}
      animate={animation}
      variants={{
        visible: {
          opacity: 1,
          y: 0,
          transition: { duration: 0.6, ease: [0.6, 0.05, -0.01, 0.9] },
        },
        hidden: { opacity: 0, y: 72 },
      }}
    >
      <Container>
        <Link className='marginLess' to={'/main-video'}>
          <FeaturedContent
            onHoverStart={() => setHovered(!hovered)}
            onHoverEnd={() => setHovered(!hovered)}
            onMouseEnter={() => onCursor('hovered')}
            onMouseLeave={() => onCursor()}
          >
            <h2 className='featured-title'>
              Welcome
              <span className='arrow'>
                {/* <div className="animatedArrow"> */}
                  <motion.svg
                    animate={{ x: hovered ? 12 : 0 }}
                    transition={
                      { duration: 0.6, ease: [0.6, 0.05, -0.01, 0.9] }
                    }
                    variants={animateVariants}
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 101 57'
                  >
                    <path
                      d='M33 34H0V24h81.429L66 7.884 73.548 0l19.877 20.763.027-.029L101 28.618 73.829 57l-7.548-7.884L80.753 34H33z'
                      fill='#FFF'
                      fillRule='evenodd'
                    />
                  </motion.svg>
                {/* </div> */}
              </span>
            </h2>
          </FeaturedContent>
          <FeaturedVideo>
            <video 
            loop 
            autoPlay  
            muted 
            playsinline webkit-playsinline webkit-playsinline="true" playsinline="true"
            // src='https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/teaser.mp4' 
            // src={trailerVideo} 
            src = 'https://ayushaopus.eu-central-1.linodeobjects.com/762109.6c9de42f.mp4'
            />
          </FeaturedVideo>
        </Link>
      </Container>
    </HomeFeaturedSection>
  );
};

export default HomeFeatured;
