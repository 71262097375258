import React, {useEffect} from 'react';
import { Video } from '../styles/homeStyles';
// import teaserVideo from '../assets/video/teaser.mp4';
// import mobile_teaser from '../assets/video/mobile/mobile_teaser.mp4';
import { Container } from '../styles/globalStyle';
import Links from '../Components/Shared/Links';
import { HomeAccordian, CopyrightAccordion } from '../Components/Homepage/HomeAbout';
import useWindowSize from '../hooks/useWindowSize';
import { analytics } from '../firebase/index'

const MainVideo = ({ setLinkSection, onCursor }) => {
  useEffect(() => {
    analytics.logEvent('Teaser_visited')
  })
  const size = useWindowSize();
  return (
    <>
      <Container className='teaser'>
        {/* <video
          className={'video'}
          controls
          controlsList='nodownload'
          // src={size.width < 430 ? mobile_teaser : teaserVideo}
          src = 'https://ayushaopus.eu-central-1.linodeobjects.com/762109.6c9de42f.mp4'
          // src='https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/teaser.mp4'
          // src={teaserVideo}
          muted
          autoPlay
          playsinline webkit-playsinline webkit-playsinline="true" playsinline="true"
          loop
          onContextMenu={(e) => e.preventDefault()}
        ></video> */}

        <div 
        style={{padding:'58.25% 0 0 0',position:'relative'}}>
        <iframe 
        src="https://player.vimeo.com/video/671597424?h=bb63849d53&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;loop=1&amp;autoplay=1" 
        // src="https://player.vimeo.com/video/671597424?autoplay=1&loop=1&autopause=0"
        frameborder="0" 
        allow="autoplay; fullscreen; picture-in-picture" 
        allowfullscreen 
        style={{position:'absolute',top:'0',left:'0',width:'100%',height:'100%'}}
        title="762109.6c9de42f2.mp4">
        </iframe>
        </div><script src="https://player.vimeo.com/api/player.js"></script>
        
        {/* <div className='overlayVideo'></div> */}
      </Container>
      <Links setLinkSection={setLinkSection} onCursor={onCursor} />
      <HomeAccordian onCursor={onCursor} />
      {/* <CopyrightAccordion onCursor={onCursor} /> */}
    </>
  );
};

export default MainVideo;
