import React from 'react';
import Jumbotron from '../../Components/jumbotron/index';
import useWindowSize from '../../hooks/useWindowSize';

export function JumbotronContainer() {
  const size = useWindowSize();
  return (
    <>
      <div className="Desktop-Container">
        <div className="Jumbo">
          {/* Jumbotron 1 */}
          <Jumbotron.Container className='commitment1'>
            <Jumbotron direction='row'>
              <Jumbotron.Pane>
                <Jumbotron.Text>
                  <p>
                    The work that I do is extremely detailed and complex in design,
                    labor intensive and takes an incredible amount of time and
                    expertise to complete; as you can imagine some of these pieces
                    will weigh in excess of 2000 lbs. (900+ kg.)~.! …depending on
                    the size and metal used.
                  </p>
                  <p>
                    A Creation of this opus will mean much more than purchasing just
                    another piece of modern contemporary exotic art; it will have
                    enormous meaning and impact to those less fortunate around the
                    world that we will help to nourish…
                  </p>
                </Jumbotron.Text>
              </Jumbotron.Pane>
              {size.width < 1000 ? (
                <Jumbotron.Pane>
                  <Jumbotron.Text>
                    <p>
                      <span>T</span>ogether!
                    </p>
                  </Jumbotron.Text>
                </Jumbotron.Pane>
              ) : null}
              <Jumbotron.Pane>
                <Jumbotron.Image
                  src={
                    // 'https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/commitment1.jpg'
                    'https://ayushaopus.eu-central-1.linodeobjects.com/5643218.f88b6e0c.jpg'
                    // require('../../assets/img/commitment/commitment1.jpg').default
                  }
                  alt=''
                  onContextMenu={(e) => e.preventDefault()}
                />
                <div className='overlay'></div>
              </Jumbotron.Pane>
            </Jumbotron>
          </Jumbotron.Container>

          {/* Jumbotron 2 */}
          <Jumbotron.Container className='commitment2'>
            <Jumbotron direction='row'>
              <Jumbotron.Pane>
                <Jumbotron.Text className={size.width < 1000 ? 'displayNone' : ''}>
                  <p>
                    <span>T</span>ogether!
                  </p>
                </Jumbotron.Text>
              </Jumbotron.Pane>
              <Jumbotron.Pane>
                <Jumbotron.Text>
                  <p>
                    I believe that <span>L</span>ife is the Art of Drawing without
                    an eraser; so let our life choices create beauty for one
                    another.
                  </p>
                </Jumbotron.Text>
              </Jumbotron.Pane>
            </Jumbotron>
          </Jumbotron.Container>

          {/* Jumbotron 3 */}
          <Jumbotron.Container className='commitment3'>
            <Jumbotron direction={size.width > 1000 ? 'row' : 'row-reverse'}>
              <Jumbotron.Pane>
                <Jumbotron.Image
                  src={
                    // 'https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/commitment2.jpg'
                    'https://ayushaopus.eu-central-1.linodeobjects.com/1590834.162876c1.jpg'
                    // require('../../assets/img/commitment/commitment2.jpg').default
                  }
                  alt=''
                  onContextMenu={(e) => e.preventDefault()}
                />
                <div className='overlay'></div>
              </Jumbotron.Pane>
              <Jumbotron.Pane>
                <Jumbotron.Text>
                  <p>
                    Though all of my works of art are unique limited and one of a
                    kind masterpieces, I may be inclined by special request to
                    create <span>B</span>espoke commissioned art - defined only by
                    the limits of your own imagination and make it come alive ~!
                  </p>
                </Jumbotron.Text>
              </Jumbotron.Pane>
            </Jumbotron>
          </Jumbotron.Container>

          {/* Jumbotron 4 */}
          <Jumbotron.Container className='commitment4'>
            <Jumbotron direction='row'>
              <Jumbotron.Pane>
                <Jumbotron.Text>
                  <p>
                    *All original masterpiece designs are copyright as intellectual
                    property.
                  </p>
                </Jumbotron.Text>
              </Jumbotron.Pane>
            </Jumbotron>
          </Jumbotron.Container>
        </div>
      </div>

      <div className="Mobile-Container">
        <div className="Jumbo">
          {/* Jumbotron 1 */}
          <div className='commitment1'>
            <div>
              <div>
                <Jumbotron.Text>
                  <p>
                    The work that I do is extremely detailed and complex in design,
                    labor intensive and takes an incredible amount of time and
                    expertise to complete; as you can imagine some of these pieces
                    will weigh in excess of 2000 lbs. (900+ kg.)~.! …depending on
                    the size and metal used.
                  </p>
                  <p>
                    A Creation of this opus will mean much more than purchasing just
                    another piece of modern contemporary exotic art; it will have
                    enormous meaning and impact to those less fortunate around the
                    world that we will help to nourish…
                  </p>
                </Jumbotron.Text>
              </div>
              {size.width < 1000 ? (
                <div>
                  <Jumbotron.Text>
                    <p>
                      <span>T</span>ogether!
                    </p>
                  </Jumbotron.Text>
                </div>
              ) : null}
              <div>
                <Jumbotron.Image
                  src={
                    // 'https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/commitment1.jpg'
                    'https://ayushaopus.eu-central-1.linodeobjects.com/5643218.f88b6e0c.jpg'
                    // require('../../assets/img/commitment/commitment1.jpg').default
                  }
                  alt=''
                  onContextMenu={(e) => e.preventDefault()}
                />
                <div className='overlay'></div>
              </div>
            </div>
          </div>

          {/* Jumbotron 2 */}
          <div className='commitment2'>
            <div>
              <div>
                <Jumbotron.Text className={size.width < 1000 ? 'displayNone' : ''}>
                  <p>
                    <span>T</span>ogether!
                  </p>
                </Jumbotron.Text>
              </div>
              <div>
                <Jumbotron.Text>
                  <p>
                    I believe that <span>L</span>ife is the Art of Drawing without
                    an eraser; so let our life choices create beauty for one
                    another.
                  </p>
                </Jumbotron.Text>
              </div>
            </div>
          </div>

          {/* Jumbotron 3 */}
          <div className='commitment3'>
            <div direction={size.width > 1000 ? 'row' : 'row-reverse'}>
              <div>
                <Jumbotron.Image
                  src={
                    // 'https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/commitment2.jpg'
                    'https://ayushaopus.eu-central-1.linodeobjects.com/1590834.162876c1.jpg'
                    // require('../../assets/img/commitment/commitment2.jpg').default
                  }
                  alt=''
                  onContextMenu={(e) => e.preventDefault()}
                />
                <div className='overlay'></div>
              </div>
              <div>
                <Jumbotron.Text>
                  <p>
                    Though all of my works of art are unique limited and one of a
                    kind masterpieces, I may be inclined by special request to
                    create <span>B</span>espoke commissioned art - defined only by
                    the limits of your own imagination and make it come alive ~!
                  </p>
                </Jumbotron.Text>
              </div>
            </div>
          </div>

          {/* Jumbotron 4 */}
          <div className='commitment4'>
            <div>
              <div>
                <Jumbotron.Text>
                  <p>
                    *All original masterpiece designs are copyright as intellectual
                    property.
                  </p>
                </Jumbotron.Text>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
