import styled, { css } from 'styled-components/macro';

export const Container = styled.div`
  flex-grow: 1;
  margin: 0 auto;
  padding: 0 32px;
  position: relative;
  max-width: 84.5%;
  // max-width: 86.8%;
  width: auto;
  height: 100%;

  .overlayVideo {
    position: absolute;
    width: 100%;
    height: 600px;
    top: -600px;
  }

  &.links {
    max-width: 88.7%;
  }

  &.teaser,
  &.main-video {
    margin: 220px auto -10px;
    @media (max-width: 500px) {
      margin: 150px auto 73px;
    }
  }

  .margin_botton_50 {
    color: transparent;
    // display: none;
    visibility: hidden;    
    @media (max-width: 1000px) {
      // margin-bottom: 40px;
      // display: block;
    }
  }

  .copyrightStatement {
    color: ${(props) => props.theme.text};
    font-size: 22px;
    margin-bottom: 12px;
    font-weight: bold;
    margin-top: 70px;
    text-align: center;
    
    @media (max-width: 1250px) {
      font-size: 20px;
    }
    @media (max-width: 700px) {
      font-size: 18px;
      margin-bottom: 10px;
    }
    @media (max-width: 500px) {
      font-size: 17px;
      margin-top: 50px;
      text-align: center;
      margin-bottom: 10px;
    }
    @media (max-width: 400px) {
      font-size: 16px;
      margin-top: 50px;
      text-align: center;
      margin-bottom: 10px;
    }
    @media (max-width: 376px) {
      font-size: 15px;
      margin-top: 50px;
      text-align: center;
      margin-bottom: 10px;
    }
    @media (max-width: 353px) {
      font-size: 14px;
      margin-top: 50px;
      text-align: center;
      margin-bottom: 10px;
    }
  }

  // @media (max-width: 1024px) {
  //   max-width: 960px;
  // }
  // @media (max-width: 1216px) {
  //   max-width: 1152px;
  // }
  // @media (max-width: 1408px) {
  //   max-width: 1170px;
  // }
  @media (max-width: 500px) {
    padding: 0 20px;
  }
  p {
    font-size: 1.6rem;
    font-weight: 700;
    color: ${(props) => props.theme.text};
    span {
      font-size: 2rem;
      font-weight: 700;
    }
  }

  ${(props) =>
    props.fluid &&
    css`
      padding: 0;
      margin: 0;
      max-width: 100%;
    `}
  .video {
    height: 550px;
    width: 100%;
    // object-fit: fill;
    object-fit: cover;
    @media (max-width: 500px) {
      height: 300px;
    }
  }
  // .teaserVideo, .mainVideo {
  //   height: 550px;
  //   width: 100%;
  //   object-fit: cover;
  //   @media (max-width: 500px) {
  //     height: 180px;
  //     object-fit: contain;
  //   }
  // }
`;

export const Flex = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  
  // &.flex {
  //   justify-content: center;
  // }

  &.flex_copyright {
    display: flex;
    justify-content: start;
    margin: 30px 0;
    flex-direction: column;
    align-items: flex-start;
    @media (max-width: 1000px) {
      margin: 50px 0 30px 0;
    }
  }

  ${(props) =>
    props.flexEnd &&
    css`
      justify-content: flex-end;
    `}

  ${(props) =>
    props.center &&
    css`
      justify-content: center;
    `}

  ${(props) =>
    props.spaceBetween &&
    css`
      justify-content: space-between;
    `}
  ${(props) =>
    props.spaceAround &&
    css`
      justify-content: space-around;
    `}

  ${(props) =>
    props.flexStart &&
    css`
      justify-content: flex-start;
    `}

  ${(props) =>
    props.noHeight &&
    css`
      height: 0;
    `}
`;

export const Cursor = styled.div`

  position: absolute;
  top: 0;
  left: 0;
  width: 32px;
  height: 32px;
  border-radius: 100%;
  // background: ${(props) => props.theme.red};
  background: linear-gradient( 60deg, #9c192f, 20%, #f42835, 40%, #aa0034, 60%, #eb002a, 80%, #b1212b 100% );
  transform: translate(-50%, -50%);
  transition: all .1s ease-in-out;
  transition-property: width, height, border;
  will-change: width, height, border, transform;
  pointer-events: none;
  z-index: 1003;
  @media (max-width: 768px) {
    display: none;
  }

  &.hovered {
    background: transparent !important;
    width: 56px;
    height: 56px;
    border: 4px solid ${(props) => props.theme.red};
  }

  &.nav-open {
    background: ${(props) => props.theme.text};
  }

  &.pointer {
    border: 4px solid ${(props) => props.theme.text} !important;
    
  }

  &.locked {
    background: transparent !important;
    width: 56px;
    height: 56px;
    border: 4px solid ${(props) => props.theme.red};
    top: ${(props) => props.theme.top};
    left: ${(props) => props.theme.left};
  }
}
`;

export const EctraSpace = styled.div`
  margin: 2rem;
`;

export const Drag = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 69px;
  height: 69px;
  margin: -32px 0 0 -32px;
  border: 5px solid ${(props) => props.theme.red};
  border-radius: 100%;
  transform: translate3d(70vw, 75vh, 0px);
  transition: opacity 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  transition-delay: 0s;
  transition-delay: 1s;
  will-change: opacity, transform;
  cursor: pointer;
  ::-webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  z-index: 1001;

  &.noDrag {
    display: none;
  }

  &.locked {
    background: transparent !important;
    width: 56px;
    height: 56px;
    border: 4px solid ${(props) => props.theme.red};
    top: 0;
    left: 0;
  }
`;

export const DragMe = styled.span`
  position: relative;
  top: 50%;
  left: 50%;
  display: block;
  color: ${(props) => props.theme.red};
  text-align: center;
  transform: translate(-50%, -50%) rotate(25deg);

  // @media (max-width: 768px) {
  //   display: none;
  // }
`;
