import React from 'react';
import Jumbotron from '../../Components/jumbotron/index';
import useWindowSize from '../../hooks/useWindowSize';

export function JumbotronContainer() {
  const size = useWindowSize();
  return (
    <>
      <div className="Desktop-Container">
        {/* Jumbotron 1 */}
        <div className="Jumbo">
          <Jumbotron.Container className='artist1'>
            <Jumbotron direction='row'>
              <Jumbotron.Pane>
                <Jumbotron.Text>
                  <p>
                    Son, Father, Friend, Teacher, Cardiac Clinical Specialist,
                    Restaurateur, Builder, Creator, Artist and Visionary.
                  </p>
                  <p>
                    A few accomplishments and accolades along the way in this
                    journey called life. A licensed professional in the field of
                    cardiac medicine and teaching, Nilesh Bhathella has always been
                    artistic by nature, fascinated by design, however this venue and
                    medium of expression only realized later in life; which has now
                    come to fruition. How is it that life has meandered into such an
                    artistic realm from that of concrete science?
                  </p>
                </Jumbotron.Text>
              </Jumbotron.Pane>
              <Jumbotron.Pane>
                <Jumbotron.Image
                  // src={require('../../assets/img/artist/artist1.jpg').default}
                  src ='https://ayushaopus.eu-central-1.linodeobjects.com/814789.02f444ed.jpg'
                  // src='https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/artist1.jpg'
                  alt=''
                  onContextMenu={(e) => e.preventDefault()}
                />
                <div className='overlay'></div>
              </Jumbotron.Pane>
            </Jumbotron>
          </Jumbotron.Container>
          {/* Jumbotron 2 */}

          <Jumbotron.Container className='artist2'>
            <Jumbotron direction='row-reverse'>
              <Jumbotron.Pane>
                <Jumbotron.Text>
                  <p>
                    <span>N</span>ilesh postulates that science in itself is art
                    speaking through the vibration of each and every heartbeat, creating the
                    ultimate art form that we are.
                  </p>
                  <p>
                    From interaction with thousands of patients throughout the
                    years, Nilesh has had a very unique opportunity to see from
                    diverse vantage points and he summarizes that the greatest
                    achievement in life is to have the ability to create the world
                    around you so that it matches your vibration and dreams; a form
                    of creative visualization to enhance your surroundings and
                    physical reality in deep meaningful ways.
                  </p>
                </Jumbotron.Text>
              </Jumbotron.Pane>
              <Jumbotron.Pane>
                <Jumbotron.Image
                  // src={require('../../assets/img/artist/artist2.jpg').default}
                  src = 'https://ayushaopus.eu-central-1.linodeobjects.com/327680.30d7c75a.jpg'
                  // src='https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/artist2.jpg'
                  alt=''
                  onContextMenu={(e) => e.preventDefault()}
                />
                <div className='overlay'></div>
              </Jumbotron.Pane>
            </Jumbotron>
          </Jumbotron.Container>
          {/* Jumbotron 3 */}

          <Jumbotron.Container className='artist3'>
            <Jumbotron direction='row'>
              <Jumbotron.Pane>
                <Jumbotron.Text>
                  <p>
                    <span>C</span>reation, vibration, energy and human spirit are
                    all encompassed as Nilesh brings together a very unique
                    combination of intricate skills and artistic vision that
                    resonates throughout this Opus. <span style={{marginLeft: '5px'}}></span> Art and Science cross here to
                    create sheer beauty for all to enjoy.
                  </p>
                </Jumbotron.Text>
              </Jumbotron.Pane>
              <Jumbotron.Pane>
                <Jumbotron.Image
                  // src='https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/artist3.jpg'
                  src  = 'https://ayushaopus.eu-central-1.linodeobjects.com/967527.b2494141.jpg'
                  // src={require('../../assets/img/artist/artist3.jpg').default}
                  alt=''
                  onContextMenu={(e) => e.preventDefault()}
                />
                <div className='overlay'></div>
              </Jumbotron.Pane>
            </Jumbotron>
          </Jumbotron.Container>

          {/* Jumbotron 4 */}

          <Jumbotron.Container className='artist4'>
            <Jumbotron direction='row-reverse'>
              <Jumbotron.Pane>
                <Jumbotron.Text>
                  <p>
                    Let the grandeur of this art vibrate the essence of life into
                    the deepest part of your soul; transforming energy into
                    creation.
                  </p>
                  <p className={size.width < 1000 ? 'displayNone' : ''}>
                    Consciousness conceives, governs, and eventually becomes your
                    physical world.
                  </p>
                </Jumbotron.Text>
              </Jumbotron.Pane>
              <Jumbotron.Pane>
                <Jumbotron.Image
                  // src='https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/artist4.jpg'
                  src = 'https://ayushaopus.eu-central-1.linodeobjects.com/290252.8dec764b.jpg'
                  // src={require('../../assets/img/artist/artist4.jpg').default}
                  alt=''
                  onContextMenu={(e) => e.preventDefault()}
                />
                <div className='overlay'></div>
              </Jumbotron.Pane>
              {size.width < 1000 ? (
                <Jumbotron.Pane>
                  <Jumbotron.Text>
                    <p>
                      Consciousness conceives, governs, and eventually becomes your
                      physical world.
                    </p>
                  </Jumbotron.Text>
                </Jumbotron.Pane>
              ) : null}
            </Jumbotron>
          </Jumbotron.Container>
        </div>
      </div>

      <div className="Mobile-Container">
        <div className="Jumbo">
          {/* Jumbotron 1 */}
          <div className='artist1'>
            <div>
              <div>
                <Jumbotron.Text>
                  <p>
                    Son, Father, Friend, Teacher, Cardiac Clinical Specialist,
                    Restaurateur, Builder, Creator, Artist and Visionary.
                  </p>
                  <p>
                    A few accomplishments and accolades along the way in this
                    journey called life. A licensed professional in the field of
                    cardiac medicine and teaching, Nilesh Bhathella has always been
                    artistic by nature, fascinated by design, however this venue and
                    medium of expression only realized later in life; which has now
                    come to fruition. How is it that life has meandered into such an
                    artistic realm from that of concrete science?
                  </p>
                </Jumbotron.Text>
              </div>
              <div>
                <Jumbotron.Image
                  // src='https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/artist1.jpg'
                  src = 'https://ayushaopus.eu-central-1.linodeobjects.com/814789.02f444ed.jpg'
                  // src={require('../../assets/img/artist/artist1.jpg').default}
                  alt=''
                  onContextMenu={(e) => e.preventDefault()}
                />
                <div className='overlay'></div>
              </div>
            </div>
          </div>

          {/* Jumbotron 2 */}

          <div className='artist2'>
            <div>
              <div>
                <Jumbotron.Text>
                  <p>
                    <span>N</span>ilesh postulates that science in itself is art
                    speaking through the vibration of each and every heartbeat, creating the
                    ultimate art form that we are.
                  </p>
                  <p>
                    From interaction with thousands of patients throughout the
                    years, Nilesh has had a very unique opportunity to see from
                    diverse vantage points and he summarizes that the greatest
                    achievement in life is to have the ability to create the world
                    around you so that it matches your vibration and dreams; a form
                    of creative visualization to enhance your surroundings and
                    physical reality in deep meaningful ways.
                  </p>
                </Jumbotron.Text>
              </div>
              <div>
                <Jumbotron.Image
                  // src='https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/artist2.jpg'
                  src = 'https://ayushaopus.eu-central-1.linodeobjects.com/327680.30d7c75a.jpg'
                  // src={require('../../assets/img/artist/artist2.jpg').default}
                  alt=''
                  onContextMenu={(e) => e.preventDefault()}
                />
                <div className='overlay'></div>
              </div>
            </div>
          </div>

          {/* Jumbotron 3 */}

          <div className='artist3'>
            <div>
              <div>
                <Jumbotron.Text>
                  <p>
                    <span>C</span>reation, vibration, energy and human spirit are
                    all encompassed as Nilesh brings together a very unique
                    combination of intricate skills and artistic vision that
                    resonates throughout this Opus.<span style={{marginLeft: '5px'}}></span> Art and Science cross here to
                    create sheer beauty for all to enjoy.
                  </p>
                </Jumbotron.Text>
              </div>
              <div>
                <Jumbotron.Image
                  // src='https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/artist3.jpg'
                  src = 'https://ayushaopus.eu-central-1.linodeobjects.com/967527.b2494141.jpg'
                  // src={require('../../assets/img/artist/artist3.jpg').default}
                  alt=''
                  onContextMenu={(e) => e.preventDefault()}
                />
                <div className='overlay'></div>
              </div>
            </div>
          </div>

          {/* Jumbotron 4 */}

          <div className='artist4'>
            <div>
              <div>
                <Jumbotron.Text>
                  <p>
                    Let the grandeur of this art vibrate the essence of life into
                    the deepest part of your soul; transforming energy into
                    creation.
                  </p>
                  <p className={size.width < 1000 ? 'displayNone' : ''}>
                    Consciousness conceives, governs, and eventually becomes your
                    physical world.
                  </p>
                </Jumbotron.Text>
              </div>
              <div>
                <Jumbotron.Image
                  // src={require('../../assets/img/artist/artist4.jpg').default}
                  src = 'https://ayushaopus.eu-central-1.linodeobjects.com/290252.8dec764b.jpg'
                  // src='https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/artist4.jpg'
                  alt=''
                  onContextMenu={(e) => e.preventDefault()}
                />
                <div className='overlay'></div>
              </div>
              {size.width < 1000 ? (
                <div>
                  <Jumbotron.Text>
                    <p>
                      Consciousness conceives, governs, and eventually becomes your
                      physical world.
                    </p>
                  </Jumbotron.Text>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
