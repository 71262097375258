import React, { useRef, useState } from 'react';
import Jumbotron from '../../Components/jumbotron/index';

// import video1 from '../../assets/video/video1.mp4';
// import video2 from '../../assets/video/video2.mp4';
// import video3 from '../../assets/video/video3.mp4';
// import video4 from '../../assets/video/video4.mp4';
// import video5 from '../../assets/video/video5.mp4';
// import mobileVideo3 from '../../assets/video/mobile/mobileVideo3.mp4'
// import mobileVideo4 from '../../assets/video/mobile/mobileVideo4.mp4'

import { useGlobalStateContext } from '../../context/globalContext';
import useWindowSize from '../../hooks/useWindowSize';
import './vision.css';

export function JumbotronContainer() {
  const { currentTheme } = useGlobalStateContext();
  const size = useWindowSize();
  const [toggle, setToggle] = useState(false)

  const videoRef1 = useRef(null);
  const videoRef2 = useRef(null);
  const videoRef3 = useRef(null);
  const videoRef4 = useRef(null);
  const videoRef5 = useRef(null);
  const ripple = useRef(null);


  setTimeout(function(){
    // toggle && console.log('Delayed True')
    toggle && setToggle(!toggle)
}, 500);



  // for videos, put a 'video' named folder in assets add videos with these names

  return (
    <div>
      <div className='Mobile-Container'>
        {/* Jumbotron 1 */}
        <div className='Jumbo'>
          {/* Jumbotron 1 */}
          <div className='vision1'>
            <div>
              <div>
                <Jumbotron.Text>
                  <p>
                    A great deal of what I do is intertwined within my vision to
                    help end <span>W</span>orld famine, hunger and poverty
                    especially for our children.
                  </p>
                  <p>
                    I encourage partnerships and connections with special people
                    around the world who would like to help in the world quest
                    to resolve this for all mankind.
                  </p>
                </Jumbotron.Text>
              </div>
              <div>
                <Jumbotron.Image
                  src={
                    // 'https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/vision&philanthropy1.jpg'
                    'https://ayushaopus.eu-central-1.linodeobjects.com/915689.afcf01867.jpg'
                    // require('../../assets/img/vision&philanthropy/vision&philanthropy1.jpg').default
                  }
                  alt=''
                  onContextMenu={(e) => e.preventDefault()}
                />
                <div className='overlay'></div>
              </div>
            </div>
          </div>

          {/* Video 1 */}

          <div className='video1'>
            <div>
              <Jumbotron.Video className='moreMarginTop'
              style={{ height: 'auto' }}  //temprory
              >
                {/* <video
                  ref={videoRef1}
                  controls
                  controlsList='nodownload'
                  // src={video1}
                  src = 'https://ayushaopus.eu-central-1.linodeobjects.com/814064.3b2caa22.mp4'
                  // src='https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/video1.mp4'
                  // src={size.width < 430 ? mobile_video1 : video1}
                  muted
                  playsInline
                  webkit-playsinline
                  webkit-playsinline='true'
                  playsInline='true'
                  autoPlay
                  loop
                  onContextMenu={(e) => e.preventDefault()}
                /> */}
                <div 
                style={{padding:'56.25% 0 0 0',position:'relative'}}>
                <iframe 
                src="https://player.vimeo.com/video/671578638?h=a47c0a4d23&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;loop=1" 
                frameborder="0" 
                allow="autoplay; fullscreen; picture-in-picture" 
                allowfullscreen 
                style={{position:'absolute',top:'0',left:'0',width:'100%',height:'100%'}}
                title="814064.3b2caa22.mp4">
                </iframe>
                </div>
                <script src="https://player.vimeo.com/api/player.js"></script>
                {/* <div className='overlayVideo'></div> */}
              </Jumbotron.Video>
              {/* <button
            onClick={() => (videoRef1.current.muted = !videoRef1.current.muted)}
          >
            unMute
          </button> */}
            </div>
          </div>

          {/* Jumbotron 2 */}
          <div className='vision2'>
            <div>
              <div>
                <Jumbotron.Text>
                  <p>
                    <span>W</span>e live in a privileged world of abundance, a
                    world where this question of a lack of sustenance should not
                    even be an issue, yet there are so many around the world
                    suffering each and every day.
                  </p>
                  <p>
                    To set as an example for all to follow, I pledge as (
                    <span>C</span>harity/dāna/sadaqah) a portion of my proceeds
                    into a fund that will go towards a resolution on this issue.
                  </p>
                  <p>
                    <span>W</span>e must all feel <span>R</span>esponsible
                    towards our fellow man regardless of race, creed or
                    religion.
                  </p>
                </Jumbotron.Text>
              </div>
              <div>
                <Jumbotron.Image
                  src={
                    // 'https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/vision&philanthropy2.jpg'
                    'https://ayushaopus.eu-central-1.linodeobjects.com/814315.e2a28988.jpg'
                    // require('../../assets/img/vision&philanthropy/vision&philanthropy2.jpg').default
                  }
                  alt=''
                  onContextMenu={(e) => e.preventDefault()}
                />
                <div className='overlay'></div>
              </div>
            </div>
          </div>

          {/* Video 2 */}

          <div className='video2'>
            <div>
              {/* <Jumbotron.VideoText classNmae={(size.width > 768 && size.width < 1000) ? 'video2above768' : null}> */}
              <Jumbotron.VideoText className='video2above768'>
                {size.width > 720 ? (
                  <p style={{ marginBottom: '40px', marginTop: '35px'}}>
                    The main virtue of social entrepreneurship is within the{' '}
                    {size.width < 365 ? <span></span> : null} “art of giving”
                    {'   '}
                    {size.width < 330 ? (
                      <ectraSpace style={{ marginLeft: '20px' }} />
                    ) : (
                      <ectraSpace style={{ marginLeft: '40px' }} />
                    )}
                    {/* ~ Sara Al Madani */}
                  </p>
                ) : size.width > 530 ? (
                  <p style={{ marginBottom: '50px', lineHeight: '130%' }}>
                    The main virtue of social entrepreneurship is within the{' '}
                    {size.width < 365 ? <span></span> : null} “art of giving”
                    {'   '}
                    {size.width < 330 ? (
                      <ectraSpace style={{ marginLeft: '20px' }} />
                    ) : (
                      <ectraSpace style={{ marginLeft: '40px' }} />
                    )}
                    {/* ~ Sara Al Madani */}
                  </p>
                ) : (
                  <p>
                    The main virtue of social entrepreneurship is within the{' '}
                    {size.width < 365 ? <br /> : null} “art of giving”{'   '}
                    {size.width < 415 ? (
                      <ectraSpace style={{ marginLeft: '20px' }} />
                    ) : size.width < 410 ? (
                      <ectraSpace style={{ marginLeft: '100px' }} />
                    ) : size.width < 368 ? (
                      <ectraSpace style={{ marginLeft: '60px' }} />
                    ) : size.width < 330 ? (
                      <ectraSpace style={{ marginLeft: '100px' }} />
                    ) : (
                      <ectraSpace style={{ marginLeft: '40px' }} />
                    )}
                    {/* {(size.width > 411) ? (
                      <span>
                        ~ Sara Al Madani
                      </span>
                    ) : ( */}
                    {/* // <span> */}
                    
                    {/* {size.width < 391 && size.width > 372 ? (
                      <p>~ Sara Al Madani</p>
                    ) : (
                      <>~ Sara Al Madani</>
                    )} */}

                    {/* //   </span> */}
                    {/* // )} */}
                  </p>
                )}
                {/* {(size.width < 411) ? (
                  <p className={size.width < 1000 ? 'margin-10' : ''}>
                    ~ Sara Al Madani
                  </p>
                ) : (
                  <p style={{ display: "none" }} className={size.width < 1000 ? 'margin-10' : ''}>
                    ~ Sara Al Madani
                  </p>
                )} */}
              </Jumbotron.VideoText>
              <Jumbotron.Video
                className={size.width < 1000 ? 'margin_vid2' : ''}
                style={{ height: 'auto' }}  //temprory
              >
                {/* <video
                  ref={videoRef2}
                  className={size.width < 1000 ? 'vid_2' : ''}
                  controls
                  controlsList='nodownload'
                  // src='https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/video2.mp4'
                  // src={video2}
                  src = 'https://ayushaopus.eu-central-1.linodeobjects.com/513406.74833a66.mp4'
                  // src={size.width < 430 ? mobile_video2 : video2}
                  muted
                  autoPlay
                  playsinline
                  webkit-playsinline
                  webkit-playsinline='true'
                  playsinline='true'
                  loop
                  onContextMenu={(e) => e.preventDefault()}
                /> */}
                <div style={{padding:'56.25% 0 0 0',position:'relative'}}>
                <iframe
                src="https://player.vimeo.com/video/671578429?h=5d93e73319&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;loop=1" 
                frameborder="0" 
                allow="autoplay; fullscreen; picture-in-picture" 
                allowfullscreen 
                style={{position:'absolute',top:'0',left:'0',width:'100%',height:'100%'}}
                title="513406.74833a66.mp4">
                </iframe>
                </div>
                <script src="https://player.vimeo.com/api/player.js"></script>
                {/* <div className='overlayVideo'></div> */}
              </Jumbotron.Video>
              {/* <button
            onClick={() => (videoRef2.current.muted = !videoRef2.current.muted)}
          >
            unMute
          </button> */}
            </div>
          </div>

          {/* Jumbotron 3 */}
          <div className='vision3'>
            <div>
              <div>
                <Jumbotron.Text>
                  <p>
                    The biggest part of my <span>E</span>thos is to bring people
                    together in a spirit of giving and knowing that we need to
                    think with an inclusive consciousness as one human race not
                    as a single individual, in order to solve the world’s
                    problems.
                  </p>
                  <p>
                    Please help me help the world come together as one human
                    race with a collective consciousness to bring this dream
                    into reality.
                  </p>
                </Jumbotron.Text>
              </div>
              <div>
                <Jumbotron.Image
                  src={
                    // 'https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/vision&philanthropy3.jpg'
                    'https://ayushaopus.eu-central-1.linodeobjects.com/202975.e43a1730.jpg'
                    // require('../../assets/img/vision&philanthropy/vision&philanthropy3.jpg').default
                  }
                  alt=''
                  onContextMenu={(e) => e.preventDefault()}
                />
                <div className='overlay'></div>
              </div>
            </div>
          </div>

          {/* Video 3 */}

          <div className='video3'>
            <div>
              <Jumbotron.VideoText>
                <p className='moreLineHeight'>
                  My belief and vision resonates within my ethos to bring
                  sustenance and nourishment to those who need it the most via
                  the positive vibration that my art will emanate.
                </p>
              </Jumbotron.VideoText>
              <Jumbotron.Video
                className={size.width < 1000 ? 'margin_vid3' : 'moreMarginTop'}
                style={{ height: 'auto' }}  //temprory
                >
                  {/* <video
                  ref={videoRef3}
                  className={size.width < 1000 ? 'shrink' : ''}
                  controls
                  controlsList='nodownload'
                  // src={mobileVideo3}
                  src = 'https://ayushaopus.eu-central-1.linodeobjects.com/678108.5141cc23.mp4'
                  // src='https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/mobileVideo3.mp4'
                  // src={size.width < 430 ? mobileVideo3 : video3}
                  muted
                  playsinline
                  webkit-playsinline
                  webkit-playsinline='true'
                  playsinline='true'
                  autoPlay
                  loop
                  onContextMenu={(e) => e.preventDefault()}
                /> */}
                <div 
                style={{padding:'56.25% 0 0 0',position:'relative'}}>
                <iframe 
                src="https://player.vimeo.com/video/671578577?h=53ee1a0d72&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;loop=1" 
                frameborder="0" 
                allow="autoplay; fullscreen; picture-in-picture" 
                allowfullscreen 
                style={{position:'absolute',top:'0',left:'0',width:'100%',height:'100%'}} 
                title="438201.12a0e317.mp4">
                </iframe>
                </div>
                <script src="https://player.vimeo.com/api/player.js"></script>
                {/* <div className='overlayVideo'></div> */}
              </Jumbotron.Video>
              {/* <button
            onClick={() => (videoRef3.current.muted = !videoRef3.current.muted)}
          >
            unMute
          </button> */}
            </div>
          </div>

          {/* Jumbotron 4 */}
          <div className='vision4'>
            <div>
              <div>
                <Jumbotron.Text>
                  <p>
                    I create art with the vision of beautifying the world around
                    us in many different, wonderful and meaningful ways… for
                    those who are fortunate enough to buy and appreciate it and
                    for those who may never see it.
                  </p>
                  <p className='jumbo4text2'>
                    This vision will undoubtedly touch everyone in their own
                    special way…
                  </p>
                </Jumbotron.Text>
              </div>
              <div>
                <Jumbotron.Image
                  src={
                    // 'https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/vision&philanthropy4.jpg'
                    'https://ayushaopus.eu-central-1.linodeobjects.com/470148.41d73f57.jpg'
                    // require('../../assets/img/vision&philanthropy/vision&philanthropy4.jpg').default
                  }
                  alt=''
                  onContextMenu={(e) => e.preventDefault()}
                />
                <div className='overlay'></div>
              </div>
            </div>
          </div>

          {/* Video 4 */}

          <div className='video4'>
            <div>
              <Jumbotron.VideoText>
                <p className='moreLineHeight'>
                  <span>M</span>y aim is to generate a positive “<span>R</span>
                  eturn to society” structure where everyone benefits.
                </p>
              </Jumbotron.VideoText>
              <Jumbotron.Video
                className={size.width < 1000 ? 'margin_vid4' : 'moreMarginTop'}
                style={{ height: 'auto' }}  //temprory
              >
                {/* <video
                  ref={videoRef4}
                  className={size.width < 1000 ? 'margin_vid_4 shrink' : ''}
                  controls
                  controlsList='nodownload'
                  // src={mobileVideo4}
                  src = 'https://ayushaopus.eu-central-1.linodeobjects.com/889510.05e50aa2.mp4'
                  // src='https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/mobileVideo4.mp4'
                  // src={size.width < 430 ? mobileVideo4 : video4}
                  muted
                  autoPlay
                  playsinline
                  webkit-playsinline
                  webkit-playsinline='true'
                  playsinline='true'
                  loop
                  onContextMenu={(e) => e.preventDefault()}
                /> */}
                <div 
                style={{padding:'56.25% 0 0 0',position:'relative'}}>
                <iframe 
                src="https://player.vimeo.com/video/671578494?h=782f79888d&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;loop=1" 
                frameborder="0" 
                allow="autoplay; fullscreen; picture-in-picture" 
                allowfullscreen 
                style={{position:'absolute',top:'0',left:'0',width:'100%',height:'100%'}} 
                title="139740.9cc14f042.mp4">
                </iframe>
                </div>
                <script src="https://player.vimeo.com/api/player.js"></script>
                {/* <div className='overlayVideo'></div> */}
              </Jumbotron.Video>
              {/* <button
            onClick={() => (videoRef4.current.muted = !videoRef4.current.muted)}
          >
            unMute
          </button> */}
            </div>
          </div>

          {/* Video 5 */}
          <div className='video5'>
            <div>
              <Jumbotron.VideoText>
                {size.width < 400 ? (
                  <p
                    style={{ marginBottom: '65px' }}
                    className={size.width < 370 ? 'text_vid_4 vid5above731' : ''}
                  >
                    “<span>G</span>iving is the best communication”
                  </p>
                ) : size.width < 358 ? (
                  <p
                    style={{ marginBottom: '65px', fontSize: '0.93rem' }}
                    className={size.width < 370 ? 'text_vid_4 vid5above731' : ''}
                  >
                    “<span>G</span>iving is the best communication”
                  </p>
                ) : size.width < 380 ? (
                  <p
                    style={{ marginBottom: '65px', fontSize: '0.93rem' }}
                    className={size.width < 370 ? 'text_vid_4 vid5above731' : ''}
                  >
                    “<span>G</span>iving is the best communication”
                  </p>
                ) : size.width < 731 ? (
                  <p
                    style={{ marginBottom: '65px' }}
                    className={size.width < 370 ? 'text_vid_4 vid5above731' : ''}
                  >
                    “<span>G</span>iving is the best communication”
                  </p>
                ) : size.width > 731 ? (
                  <p
                    style={{ marginBottom: '65px' }}
                    className={size.width < 370 ? 'text_vid_4 vid5above731' : ''}
                  >
                    “<span>G</span>iving is the best communication”
                  </p>
                ) : (
                  <p className={size.width < 370 ? 'text_vid_4 vid5above731' : ''}>
                    “<span>G</span>iving is the best communication”
                  </p>
                )}
              </Jumbotron.VideoText>
              <Jumbotron.Video
              style={{ height: 'auto' }}  //temprory
              >
                {/* <video
                  ref={videoRef5}
                  className={size.width < 1000 ? 'margin_vid_5 shrink' : ''}
                  controls
                  controlsList='nodownload'
                  // src={video5}
                  src = 'https://ayushaopus.eu-central-1.linodeobjects.com/850139.6997488d.mp4'
                  // src='https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/video5.mp4'
                  // src={size.width < 430 ? mobile_video5 : video5}
                  muted
                  autoPlay
                  playsinline
                  webkit-playsinline
                  webkit-playsinline='true'
                  playsinline='true'
                  loop
                  onContextMenu={(e) => e.preventDefault()}
                /> */}
                <div 
                style={{padding:'56.25% 0 0 0',position:'relative'}}>
                <iframe 
                src="https://player.vimeo.com/video/671578473?h=1b5721003d&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;loop=1" 
                frameborder="0" 
                allow="autoplay; fullscreen; picture-in-picture" 
                allowfullscreen 
                style={{position:'absolute',top:'0',left:'0',width:'100%',height:'100%'}} 
                title="850139.6997488d.mp4">
                </iframe>
                </div>
                <script src="https://player.vimeo.com/api/player.js"></script>
                {/* <div className='overlayVideo'></div> */}
              </Jumbotron.Video>
            </div>
          </div>


          {/* Ayusha Opus Logo */}

          <div class={`hover15 columnss`}>
                  <div>
                    <figure 
                    ref={ripple}
                       onClick={() => {
                        setToggle(!toggle)
                       }}
                      className={`${toggle && 'rippleForMobile'}`}
                    >
                      <img
                        className='AO_LOGOVISION'
                        src={
                          currentTheme === 'dark'
                            // ? require('../../assets/img/vision&philanthropy/AO_Logo_on_black.png').default
                            ? 'https://ayushaopus.eu-central-1.linodeobjects.com/762098.2bf20b10.png'
                            // : require('../../assets/img/vision&philanthropy/AO_Logo_on_white.png').default
                            : 'https://ayushaopus.eu-central-1.linodeobjects.com/841209.d5c9946e.png'
                        }
                        alt='AO_logo'
                        onContextMenu={(e) => e.preventDefault()}
                      />
                    </figure>
                  </div>
                  <div className='dontshow'>
                    <figure>
                      <img
                       className='AO_LOGOVISION'
                       src={
                         currentTheme === 'dark'
                          //  ? require('../../assets/img/vision&philanthropy/AO_Logo_on_black.png').default
                            ? 'https://ayushaopus.eu-central-1.linodeobjects.com/762098.2bf20b10.png'
                          //  : require('../../assets/img/vision&philanthropy/AO_Logo_on_white.png').default
                            : 'https://ayushaopus.eu-central-1.linodeobjects.com/841209.d5c9946e.png'
                       }
                        alt='AO_logo'
                        onContextMenu={(e) => e.preventDefault()}
                      />
                    </figure>
                  </div>
                  <div className='dontshow'>
                    <figure>
                      <img
                        className='AO_LOGOVISION'
                        src={
                          currentTheme === 'dark'
                            // ? require('../../assets/img/vision&philanthropy/AO_Logo_on_black.png').default
                            ? 'https://ayushaopus.eu-central-1.linodeobjects.com/762098.2bf20b10.png'
                            // : require('../../assets/img/vision&philanthropy/AO_Logo_on_white.png').default
                            : 'https://ayushaopus.eu-central-1.linodeobjects.com/841209.d5c9946e.png'
                        }
                        alt='AO_logo'
                        onContextMenu={(e) => e.preventDefault()}
                      />
                    </figure>
                  </div>
                </div>
                

          {/* <Jumbotron.Image
            className='AO_LOGO_VISION'
            src={
              currentTheme === 'dark'
                ? require('../../assets/img/vision&philanthropy/AO_Logo_on_black.png')
                    .default
                : require('../../assets/img/vision&philanthropy/AO_Logo_on_white.png')
                    .default
            }
            alt='AO_logo'
            onContextMenu={(e) => e.preventDefault()}
            onContextMenu={(e) => e.preventDefault()}
          /> */}
          <br />
          <Jumbotron.Container className='logo_credits'>
            <Jumbotron.Text className='bold'>
              <p style={{ fontSize: '17px' }}>Vision Statement</p>
            </Jumbotron.Text>
            {/* <Jumbotron.Text className='center'>
          <p>Ayusha Opus ~ the Art of Giving ~ a Return to Society ~</p>
        </Jumbotron.Text>
        <Jumbotron.Text className='center'>
          <p>Abundant Sustenance for all Mankind ~ Alpha and Omega</p>
        </Jumbotron.Text> */}
            <Jumbotron.Text className='center'>
              <p style={{ fontSize: '15.4px' }}>Ayusha Opus</p>
            </Jumbotron.Text>
            <Jumbotron.Text className='center credit1'>
              <p style={{ fontSize: '15.4px' }}>
                {' '}
                The Art of Giving ~ a Return to Society
              </p>
            </Jumbotron.Text>
            <Jumbotron.Text className='center'>
              <p style={{ fontSize: '15.4px' }}>
                Abundant Sustenance for all Mankind{' '}
              </p>
            </Jumbotron.Text>
            <Jumbotron.Text className='center credit2'>
              <p style={{ fontSize: '15.4px' }}>Alpha and Omega</p>
            </Jumbotron.Text>
          </Jumbotron.Container>
        </div>
      </div>

      <div className='Desktop-Container'>
        {/* Jumbotron 1 */}
        <div className='Jumbo'>
          {/* Jumbotron 1 */}
          <Jumbotron.Container>
            <Jumbotron direction='row'>
              <Jumbotron.Pane>
                <Jumbotron.Text>
                  <p>
                    A great deal of what I do is intertwined within my vision to
                    help end <span>W</span>orld famine, hunger and poverty
                    especially for our children.
                  </p>
                  <p>
                    I encourage partnerships and connections with special people
                    around the world who would like to help in the world quest
                    to resolve this for all mankind.
                  </p>
                </Jumbotron.Text>
              </Jumbotron.Pane>
              <Jumbotron.Pane>
                <Jumbotron.Image
                  src={
                    // 'https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/vision&philanthropy1.jpg'
                    'https://ayushaopus.eu-central-1.linodeobjects.com/915689.afcf01867.jpg'
                    // require('../../assets/img/vision&philanthropy/vision&philanthropy1.jpg').default
                  }
                  alt=''
                  onContextMenu={(e) => e.preventDefault()}
                />
                <div className='overlay'></div>
              </Jumbotron.Pane>
            </Jumbotron>
          </Jumbotron.Container>

          {/* Video 1 */}

          <Jumbotron.Container className='video1'>
            <Jumbotron direction='column'>
              <Jumbotron.Video className='moreMarginTop'
              style={{ height: 'auto' }}  //temprory
              >
                {/* <video
                  ref={videoRef1}
                  controls
                  controlsList='nodownload'
                  // src={video1}
                  src = 'https://ayushaopus.eu-central-1.linodeobjects.com/814064.3b2caa22.mp4'
                  // src='https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/video1.mp4'
                  muted
                  playsinline
                  webkit-playsinline
                  webkit-playsinline='true'
                  playsinline='true'
                  autoPlay
                  loop
                  onContextMenu={(e) => e.preventDefault()}
                /> */}
                <div 
                style={{padding:'56.25% 0 0 0',position:'relative'}}>
                <iframe 
                src="https://player.vimeo.com/video/671578638?h=a47c0a4d23&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;loop=1&amp;muted=1&amp;autoplay=1" 
                frameborder="0" 
                allow="autoplay; fullscreen; picture-in-picture" 
                allowfullscreen 
                style={{position:'absolute',top:'0',left:'0',width:'100%',height:'100%'}}
                title="814064.3b2caa22.mp4">
                </iframe>
                </div>
                <script src="https://player.vimeo.com/api/player.js"></script>
                {/* <div className='overlayVideo'></div> */}
              </Jumbotron.Video>
              {/* <button
            onClick={() => (videoRef1.current.muted = !videoRef1.current.muted)}
          >
            unMute
          </button> */}
            </Jumbotron>
          </Jumbotron.Container>

          {/* Jumbotron 2 */}
          <Jumbotron.Container className='vision2'>
            <Jumbotron direction='row-reverse'>
              <Jumbotron.Pane>
                <Jumbotron.Text>
                  <p>
                    <span>W</span>e live in a privileged world of abundance, a
                    world where this question of a lack of sustenance should not
                    even be an issue, yet there are so many around the world
                    suffering each and every day.
                  </p>
                  <p>
                    To set as an example for all to follow, I pledge as (
                    <span>C</span>harity/dāna/sadaqah) a portion of my proceeds
                    into a fund that will go towards a resolution on this issue.
                  </p>
                  <p>
                    <span>W</span>e must all feel <span>R</span>esponsible
                    towards our fellow man regardless of race, creed or
                    religion.
                  </p>
                </Jumbotron.Text>
              </Jumbotron.Pane>
              <Jumbotron.Pane>
                <Jumbotron.Image
                  src={
                    // 'https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/vision&philanthropy2.jpg'
                    'https://ayushaopus.eu-central-1.linodeobjects.com/814315.e2a28988.jpg'
                    // require('../../assets/img/vision&philanthropy/vision&philanthropy2.jpg').default
                  }
                  alt=''
                  onContextMenu={(e) => e.preventDefault()}
                />
                <div className='overlay'></div>
              </Jumbotron.Pane>
            </Jumbotron>
          </Jumbotron.Container>

          {/* Video 2 */}

          <Jumbotron.Container className='video2'>
            <Jumbotron direction='column'>
              <Jumbotron.VideoText>
                {size.width < 1012 ? (
                  <p className='moreLineHeight' style={{ marginBottom: '50px'}}>
                    The main virtue of social entrepreneurship is within the{' '}
                    {size.width < 365 ? <br /> : null} “art of giving”{' '}
                  </p>
                ) : size.width < 732 ? (
                  <p className='moreLineHeight' style={{ marginBottom: '80px' }}>
                    The main virtue of social entrepreneurship is within the{' '}
                    {size.width < 365 ? <br /> : null} “art of giving”{' '}
                  </p>
                ) : (
                  <p className='moreLineHeight'>
                    The main virtue of social entrepreneurship is within the{' '}
                    {size.width < 365 ? <br /> : null} “art of giving”{' '}
                  </p>
                  // <></>
                )}

                {/* <p className={size.width < 1000 ? 'margin-10' : ''}>
              …Sara Al Madani
            </p> */}
              </Jumbotron.VideoText>
              <Jumbotron.Video
                className={size.width < 1000 ? 'margin_vid2' : ''}
                style={{ height: 'auto' }}  //temprory
              >
                {/* <video
                  ref={videoRef2}
                  className={size.width < 1000 ? 'vid_2' : ''}
                  controls
                  controlsList='nodownload'
                  // src='https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/video2.mp4'
                  // src={video2}
                  src = 'https://ayushaopus.eu-central-1.linodeobjects.com/513406.74833a66.mp4'
                  muted
                  autoPlay
                  playsinline
                  webkit-playsinline
                  webkit-playsinline='true'
                  playsinline='true'
                  loop
                  onContextMenu={(e) => e.preventDefault()}
                /> */}
                <div style={{padding:'56.25% 0 0 0',position:'relative'}}>
                <iframe
                src="https://player.vimeo.com/video/671578429?h=5d93e73319&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;loop=1&amp;muted=1&amp;autoplay=1" 
                frameborder="0" 
                allow="autoplay; fullscreen; picture-in-picture" 
                allowfullscreen 
                style={{position:'absolute',top:'0',left:'0',width:'100%',height:'100%'}}
                title="513406.74833a66.mp4">
                </iframe>
                </div>
                <script src="https://player.vimeo.com/api/player.js"></script>
                {/* <div className='overlayVideo'></div> */}
              </Jumbotron.Video>
              {/* <button
            onClick={() => (videoRef2.current.muted = !videoRef2.current.muted)}
          >
            unMute
          </button> */}
            </Jumbotron>
          </Jumbotron.Container>

          {/* Jumbotron 3 */}
          <Jumbotron.Container>
            <Jumbotron direction='row'>
              <Jumbotron.Pane>
                <Jumbotron.Text>
                  <p>
                    The biggest part of my <span>E</span>thos is to bring people
                    together in a spirit of giving and knowing that we need to
                    think with an inclusive consciousness as one human race not
                    as a single individual, in order to solve the world’s
                    problems.
                  </p>
                  <p>
                    Please help me help the world come together as one human
                    race with a collective consciousness to bring this dream
                    into reality.
                  </p>
                </Jumbotron.Text>
              </Jumbotron.Pane>
              <Jumbotron.Pane>
                <Jumbotron.Image
                  src={
                    // 'https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/vision&philanthropy3.jpg'
                    'https://ayushaopus.eu-central-1.linodeobjects.com/202975.e43a1730.jpg'
                    // require('../../assets/img/vision&philanthropy/vision&philanthropy3.jpg').default
                  }
                  alt=''
                  onContextMenu={(e) => e.preventDefault()}
                />
                <div className='overlay'></div>
              </Jumbotron.Pane>
            </Jumbotron>
          </Jumbotron.Container>

          {/* Video 3 */}

          <Jumbotron.Container className='video3'>
            <Jumbotron direction='column'>
              <Jumbotron.VideoText>
                <p className='moreLineHeight'>
                  My belief and vision resonates within my ethos to bring
                  sustenance and nourishment to those who need it the most via
                  the positive vibration that my art will emanate.
                </p>
              </Jumbotron.VideoText>
              <Jumbotron.Video
                style={{ height: 'auto' }}  //temprory
                className={size.width < 1000 ? 'margin_vid3' : 'marginTop35'}
              >
                {/* <video
                  ref={videoRef3}
                  controls
                  controlsList='nodownload'
                  // src={video3}
                  src = 'https://ayushaopus.eu-central-1.linodeobjects.com/438201.12a0e317.mp4'
                  // src={mobileVideo3}
                  // src='https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/mobileVideo3.mp4'
                  muted
                  playsinline
                  webkit-playsinline
                  webkit-playsinline='true'
                  playsinline='true'
                  autoPlay
                  loop
                  onContextMenu={(e) => e.preventDefault()}
                /> */}
                <div 
                style={{padding:'56.25% 0 0 0',position:'relative'}}>
                <iframe 
                src="https://player.vimeo.com/video/671578577?h=53ee1a0d72&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;loop=1&amp;muted=1&amp;autoplay=1" 
                frameborder="0" 
                allow="autoplay; fullscreen; picture-in-picture" 
                allowfullscreen 
                style={{position:'absolute',top:'0',left:'0',width:'100%',height:'100%'}} 
                title="438201.12a0e317.mp4">
                </iframe>
                </div>
                <script src="https://player.vimeo.com/api/player.js"></script>

                {/* <div className='overlayVideo'></div> */}
              </Jumbotron.Video>
              {/* <button
            onClick={() => (videoRef3.current.muted = !videoRef3.current.muted)}
          >
            unMute
          </button> */}
            </Jumbotron>
          </Jumbotron.Container>

          {/* Jumbotron 4 */}
          <Jumbotron.Container>
            <Jumbotron direction='row-reverse'>
              <Jumbotron.Pane>
                <Jumbotron.Text>
                  <p>
                    I create art with the vision of beautifying the world around
                    us in many different, wonderful and meaningful ways… for
                    those who are fortunate enough to buy and appreciate it and
                    for those who may never see it.
                  </p>
                  <p className='jumbo4text2'>
                    This vision will undoubtedly touch everyone in their own
                    special way…
                  </p>
                </Jumbotron.Text>
              </Jumbotron.Pane>
              <Jumbotron.Pane>
                <Jumbotron.Image
                  src={
                    // 'https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/vision&philanthropy4.jpg'
                    'https://ayushaopus.eu-central-1.linodeobjects.com/470148.41d73f57.jpg'
                    // require('../../assets/img/vision&philanthropy/vision&philanthropy4.jpg').default
                  }
                  alt=''
                  onContextMenu={(e) => e.preventDefault()}
                />
                <div className='overlay'></div>
              </Jumbotron.Pane>
            </Jumbotron>
          </Jumbotron.Container>

          {/* Video 4 */}

          <Jumbotron.Container className='video4'>
            <Jumbotron direction='column'>
              <Jumbotron.VideoText>
                <p className='moreLineHeight'>
                  <span>M</span>y aim is to generate a positive “<span>R</span>
                  eturn to society” structure where everyone benefits.
                </p>
              </Jumbotron.VideoText>
              <Jumbotron.Video
                style={{ height: 'auto' }}  //temprory
                className={size.width < 1000 ? 'margin_vid4' : 'marginTop35'}
              >
                {/* <video
                  ref={videoRef4}
                  className={size.width < 1000 ? 'margin_vid_4' : ''}
                  controls
                  controlsList='nodownload'
                  // src={mobileVideo4}
                  src = 'https://ayushaopus.eu-central-1.linodeobjects.com/889510.05e50aa2.mp4'
                  // src='https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/mobileVideo4.mp4'
                  muted
                  autoPlay
                  playsinline
                  webkit-playsinline
                  webkit-playsinline='true'
                  playsinline='true'
                  loop
                  onContextMenu={(e) => e.preventDefault()}
                /> */}
                <div 
                style={{padding:'56.25% 0 0 0',position:'relative'}}>
                <iframe 
                src="https://player.vimeo.com/video/671578494?h=782f79888d&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;loop=1&amp;muted=1&amp;autoplay=1" 
                frameborder="0" 
                allow="autoplay; fullscreen; picture-in-picture" 
                allowfullscreen 
                style={{position:'absolute',top:'0',left:'0',width:'100%',height:'100%'}} 
                title="139740.9cc14f042.mp4">
                </iframe>
                </div>
                <script src="https://player.vimeo.com/api/player.js"></script>
                {/* <div className='overlayVideo'></div> */}
              </Jumbotron.Video>
              {/* <button
            onClick={() => (videoRef4.current.muted = !videoRef4.current.muted)}
          >
            unMute
          </button> */}
            </Jumbotron>
          </Jumbotron.Container>

          {/* Video 5 */}

          <Jumbotron.Container>
            <Jumbotron direction='column'>
              <Jumbotron.VideoText>
                <p className={size.width < 370 ? 'text_vid_4' : ''}>
                  “<span>G</span>iving is the best communication”
                </p>
              </Jumbotron.VideoText>
              <Jumbotron.Video
              style={{ height: 'auto' }}  //temprory
              // style={{ height: 'auto' }}  //temprory
              >
                {/* <video
                  ref={videoRef5}
                  className={size.width < 1000 ? 'margin_vid_5' : 'margin_top_10'}
                  controls
                  controlsList='nodownload'
                  // src={video5}
                  src = 'https://ayushaopus.eu-central-1.linodeobjects.com/850139.6997488d.mp4'
                  // src='https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/video5.mp4'
                  muted
                  autoPlay
                  playsinline
                  webkit-playsinline
                  webkit-playsinline='true'
                  playsinline='true'
                  loop
                  onContextMenu={(e) => e.preventDefault()}
                /> */}
                <div 
                style={{padding:'56.25% 0 0 0',position:'relative'}}>
                <iframe 
                src="https://player.vimeo.com/video/671578473?h=1b5721003d&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;loop=1&amp;muted=1&amp;autoplay=1" 
                frameborder="0" 
                allow="autoplay; fullscreen; picture-in-picture" 
                allowfullscreen 
                style={{position:'absolute',top:'0',left:'0',width:'100%',height:'100%'}} 
                title="850139.6997488d.mp4">
                </iframe>
                </div>
                <script src="https://player.vimeo.com/api/player.js"></script>
                {/* <div className='overlayVideo'></div> */}
              </Jumbotron.Video>
              {/* <button
            onClick={() => (videoRef5.current.muted = !videoRef5.current.muted)}
          >
            unMute
          </button> */}
            </Jumbotron>
          </Jumbotron.Container>

          {/* Ayusha Opus Logo */}

          <div class='hover15 columnss'>
            <div>
              <figure>
                      <img
                        className='AO_LOGOVISION'
                        src={
                          currentTheme === 'dark'
                            // ? require('../../assets/img/vision&philanthropy/AO_Logo_on_black.png').default
                            ? 'https://ayushaopus.eu-central-1.linodeobjects.com/762098.2bf20b10.png'
                            // : require('../../assets/img/vision&philanthropy/AO_Logo_on_white.png').default
                            : 'https://ayushaopus.eu-central-1.linodeobjects.com/841209.d5c9946e.png'
                        }
                        alt='AO_logo'
                        onContextMenu={(e) => e.preventDefault()}
                      /> 
                    </figure>
            </div>
            <div className='dontshow'>
                    <figure>
                      <img
                        className='AO_LOGOVISION'
                        src={
                          currentTheme === 'dark'
                            // ? require('../../assets/img/vision&philanthropy/AO_Logo_on_black.png').default
                            ? 'https://ayushaopus.eu-central-1.linodeobjects.com/762098.2bf20b10.png'
                            // : require('../../assets/img/vision&philanthropy/AO_Logo_on_white.png').default
                            : 'https://ayushaopus.eu-central-1.linodeobjects.com/841209.d5c9946e.png'
                        }
                        alt='AO_logo'
                        onContextMenu={(e) => e.preventDefault()}
                        onContextMenu={(e) => e.preventDefault()}
                      /> 
                    </figure>
                  </div>
            <div className='dontshow'>
                    <figure>
                      <img
                        className='AO_LOGOVISION'
                        src={
                          currentTheme === 'dark'
                            // ? require('../../assets/img/vision&philanthropy/AO_Logo_on_black.png').default
                            ? 'https://ayushaopus.eu-central-1.linodeobjects.com/762098.2bf20b10.png'
                            // : require('../../assets/img/vision&philanthropy/AO_Logo_on_white.png').default
                            : 'https://ayushaopus.eu-central-1.linodeobjects.com/841209.d5c9946e.png'
                        }
                        alt='AO_logo'
                        onContextMenu={(e) => e.preventDefault()}
                      /> 
                    </figure>
                  </div>
          </div>

          {/* <Jumbotron.Image
            className='AO_LOGO_VISION'
            src={
              currentTheme === 'dark'
                ? require('../../assets/img/vision&philanthropy/AO_Logo_on_black.png')
                    .default
                : require('../../assets/img/vision&philanthropy/AO_Logo_on_white.png')
                    .default
            }
            alt='AO_logo'
            onContextMenu={(e) => e.preventDefault()}
            onContextMenu={(e) => e.preventDefault()}
          /> */}

          <Jumbotron.Container className='logo_credits'>
            <Jumbotron.Text className='bold'>
              <p>Vision Statement</p>
            </Jumbotron.Text>
            {/* <Jumbotron.Text className='center'>
          <p>Ayusha Opus ~ the Art of Giving ~ a Return to Society ~</p>
        </Jumbotron.Text>
        <Jumbotron.Text className='center'>
          <p>Abundant Sustenance for all Mankind ~ Alpha and Omega</p>
        </Jumbotron.Text> */}
            <Jumbotron.Text className='center'>
              <p>Ayusha Opus</p>
            </Jumbotron.Text>
            <Jumbotron.Text className='center credit1'>
              <p> The Art of Giving ~ a Return to Society</p>
            </Jumbotron.Text>
            <Jumbotron.Text className='center'>
              <p>Abundant Sustenance for all Mankind </p>
            </Jumbotron.Text>
            <Jumbotron.Text className='center credit2'>
              <p>Alpha and Omega</p>
            </Jumbotron.Text>
          </Jumbotron.Container>
        </div>
      </div>
    </div>
  );
}