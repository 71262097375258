import React from 'react';
import Jumbotron from '../../Components/jumbotron/index';

export function JumbotronContainer() {
  return (
    <div>
      <div className='Mobile-Container'>
        {/* Jumbotron 1 */}
        <div className='Jumbo'>
          {/* Jumbotron 1 */}
          <div className='philosophy1'>
            <div>
              <div>
                <Jumbotron.Text>
                  <p>
                    I’ve always felt that creating a wonderful environment for
                    people to be surrounded by is the key to success which
                    creates a paradise of <span>H</span>ope. I simply like to
                    see people enjoy my art.
                  </p>
                  <p>
                    I aim to be brave in my artistic vision and therefore I am
                    able to create the worlds’ largest and most intricate
                    complex designs in metal art for you and your loved ones to
                    enjoy.
                  </p>
                </Jumbotron.Text>
              </div>
              <div>
                <Jumbotron.Image
                  src={
                    // 'https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/philosophy1.jpeg'
                    'https://ayushaopus.eu-central-1.linodeobjects.com/171406.245c981e.jpg'
                    // require('../../assets/img/philosophy/philosophy1.jpeg').default
                  }
                  alt=''
                />
                <div className='overlay'></div>
              </div>
            </div>
          </div>

          {/* Jumbotron 2 */}
          <div className='philosophy2'>
            <div>
              <div>
                <Jumbotron.Text>
                  <p>
                    The energy emanating from these huge pieces of metal art can
                    be felt with such magnetism as it intertwines with your
                    vibration and energy.
                  </p>
                  <p>
                    Let imagination design your surroundings and create a
                    feeling of <span>A</span>bundance.
                  </p>
                </Jumbotron.Text>
              </div>
              <div>
                <Jumbotron.Image
                  src={
                    // 'https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/philosophy2.jpg'
                    'https://ayushaopus.eu-central-1.linodeobjects.com/790242.1137f30d.jpg'
                    // require('../../assets/img/philosophy/philosophy2.jpg').default
                  }
                  alt=''
                />
                <div className='overlay'></div>
              </div>
            </div>
          </div>

          {/* Jumbotron 3 */}
          <div className='philosophy3'>
            <div>
              <div>
                <Jumbotron.Text>
                  <p>
                    I also specialize and take great pride in making very exotic
                    modern <span>P</span>ublic <span>A</span>rt so that
                    communities are able to enjoy such artistic creations that
                    may not be otherwise possible.
                  </p>
                </Jumbotron.Text>
              </div>
              <div>
                <Jumbotron.Image
                  src={
                    // 'https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/philosophy3.jpg'
                    'https://ayushaopus.eu-central-1.linodeobjects.com/260615.10dfb944.jpg'
                    // require('../../assets/img/philosophy/philosophy3.jpg').default
                  }
                  alt=''
                />
                <div className='overlay'></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='Desktop-Container'>
        {/* Jumbotron 1 */}
        <div className='Jumbo'>
          <Jumbotron.Container className='philosophy1'>
            <Jumbotron direction='row'>
              <Jumbotron.Pane>
                <Jumbotron.Text>
                  <p>
                    I’ve always felt that creating a wonderful environment for
                    people to be surrounded by is the key to success which
                    creates a paradise of <span>H</span>ope. I simply like to
                    see people enjoy my art.
                  </p>
                  <p>
                    I aim to be brave in my artistic vision and therefore I am
                    able to create the worlds’ largest and most intricate
                    complex designs in metal art for you and your loved ones to
                    enjoy.
                  </p>
                </Jumbotron.Text>
              </Jumbotron.Pane>
              <Jumbotron.Pane>
                <Jumbotron.Image
                  src={
                    // 'https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/philosophy1.jpeg'
                    'https://ayushaopus.eu-central-1.linodeobjects.com/171406.245c981e.jpg'
                    // require('../../assets/img/philosophy/philosophy1.jpeg').default
                  }
                  alt=''
                  onContextMenu={(e) => e.preventDefault()}
                />
                <div className='overlay'></div>
              </Jumbotron.Pane>
            </Jumbotron>
          </Jumbotron.Container>

          {/* Jumbotron 2 */}
          <Jumbotron.Container className='philosophy2'>
            <Jumbotron direction='row-reverse'>
              <Jumbotron.Pane>
                <Jumbotron.Text>
                  <p>
                    The energy emanating from these huge pieces of metal art can
                    be felt with such magnetism as it intertwines with your
                    vibration and energy.
                  </p>
                  <p>
                    Let imagination design your surroundings and create a
                    feeling of <span>A</span>bundance.
                  </p>
                </Jumbotron.Text>
              </Jumbotron.Pane>
              <Jumbotron.Pane>
                <Jumbotron.Image
                  src={
                    // 'https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/philosophy2.jpg'
                    'https://ayushaopus.eu-central-1.linodeobjects.com/790242.1137f30d.jpg'
                    // require('../../assets/img/philosophy/philosophy2.jpg').default
                  }
                  alt=''
                  onContextMenu={(e) => e.preventDefault()}
                />
                <div className='overlay'></div>
              </Jumbotron.Pane>
            </Jumbotron>
          </Jumbotron.Container>

          {/* Jumbotron 3 */}
          <Jumbotron.Container className='philosophy3'>
            <Jumbotron direction='row'>
              <Jumbotron.Pane>
                <Jumbotron.Text>
                  <p>
                    I also specialize and take great pride in making very exotic
                    modern <span>P</span>ublic <span>A</span>rt so that
                    communities are able to enjoy such artistic creations that
                    may not be otherwise possible.
                  </p>
                </Jumbotron.Text>
              </Jumbotron.Pane>
              <Jumbotron.Pane>
                <Jumbotron.Image
                  src={
                    // 'https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/philosophy3.jpg'
                    'https://ayushaopus.eu-central-1.linodeobjects.com/260615.10dfb944.jpg'
                    // require('../../assets/img/philosophy/philosophy3.jpg').default
                  }
                  alt=''
                  onContextMenu={(e) => e.preventDefault()}
                />
                <div className='overlay'></div>
              </Jumbotron.Pane>
            </Jumbotron>
          </Jumbotron.Container>
        </div>
      </div>
    </div>
  );
}
