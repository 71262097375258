import React, {useEffect} from 'react';
import { CopyrightHeader, CopyrightMain, CopyrightPara, CopyrightSubHeading, CopyrightStatement, CopyrightLogo } from '../styles/copyright'

//Styled Component
import { createGlobalStyle, ThemeProvider } from 'styled-components/macro';
import { normalize } from 'styled-normalize';

import CustomCursor from '../Components/CustomCursor';
import { CopyrightHomeAccordian } from '../Components/Homepage/HomeAbout';

import {
    useGlobalStateContext,
    useGlobalDispatchContext,
  } from '../context/globalContext';

import { ReactComponent as PoloYSVG } from '../assets/svg/AO_Logo_Isotype_RGB-01.svg';
import { analytics } from '../firebase/index'



const GlobalStyle = createGlobalStyle`
  ${normalize}
  * {
    text-decoration: none;
    cursor: none;
  }

  html {
    box-sizing: border-box;
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
  }

  body {
    font-family: 'Lato', sans-serif;
    background: ${(props) => props.theme.background};
    overscroll-behavior: none;
    overflow-x: hidden;
  }

  /*  @font-face {
      font-family: 'Caviar Dreams';
      font-style: normal;
      font-weight: 300;
      src: local('Caviar Dreams'), url('../assets/fonts/CaviarDreams.tff') format('woff');
    }*/


  ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #6d6d6d;
  }

  ::-webkit-scrollbar {
    width: 2px;
    @media (max-width: 768px) {
      width: 0px;
      display: none;
      }
  }

  ::-webkit-scrollbar-track {
    background-color: #4e555b;
  }
`;

export default function Copyright({ setLinkSection, onCursor})  {
useEffect(() => {
    analytics.logEvent('Copyright_Page_visited')
  }, []);

    const darkTheme = {
        background: '#000',
        text: '#fff',
        red: '#f42835',
        // red: 'linear-gradient(to right,#9c192f,5%,#f42835,20%,#aa0034,80%,#eb002a,95%,#b1212b)',
      };
      const lightTheme = {
        text: '#000',
        background: '#fff',
        red: '#f42835',
      };
      const { currentTheme, cursorStyles } = useGlobalStateContext();
      const dispatch = useGlobalDispatchContext();

    return (
        <ThemeProvider theme={currentTheme === 'light' ? lightTheme : darkTheme}>
            <GlobalStyle />
            <CustomCursor  />
            <CopyrightMain>
                {/* <CopyrightHeader>
                Ayusha Metal Art & Design Inc. <br /> Terms of Use Agreement, disclaimers and caveats.
                </CopyrightHeader> */}
                <CopyrightLogo>
                  <PoloYSVG
                    className={'poloySVG'}
                    onMouseEnter={() => onCursor('pointer')}
                    onMouseLeave={onCursor}
                  />
                </CopyrightLogo>

                <CopyrightHeader>
                Ayusha Opus <br /> Metal Art & Design Inc.
                </CopyrightHeader>

                <CopyrightStatement>
                Terms of Use Agreement
                </CopyrightStatement>

                <CopyrightPara>By using this website you acknowledge that you have read the Terms of Use Agreement and the disclaimers and caveats contained in this website, and that you accept and will be bound by the terms thereof.</CopyrightPara>
                <CopyrightSubHeading>General</CopyrightSubHeading>
                <CopyrightPara>Please read all of the information on this page carefully before using Ayusha Opus Metal Art & Design Inc. Website operated by Ayusha Metal Art & Design Inc. (Federal Incorportated Entity) and Nilesh Bhathella.</CopyrightPara>
                <CopyrightPara>The content displayed on this website is the intellectual property of Ayusha Opus Metal Art & Design Inc. and Nilesh Bhathella.  You may not reuse, republish, or reprint such content without our formal express written consent.</CopyrightPara>
                <CopyrightPara>All information posted is merely for educational and informational purposes.  It is not intended as a substitute for any professional advice.  Should you decide to act upon any information on this website, you do so at your own risk.</CopyrightPara>
                <CopyrightPara>While the information on this website has been verified to the best of our abilities, we cannot guarantee that there are no mistakes or errors.</CopyrightPara>
                <CopyrightPara>We reserve the right to change this policy at any given time, of which you will not be promptly updated; unless you agree to read this disclaimer, which is your responsibility prior to using this website each time in order to be updated on any changes respectively that may or not be of any concern to you.</CopyrightPara>
                <CopyrightPara>If you want to make sure that you are up to date with the latest changes, we strongly advise you to visit this page frequently.</CopyrightPara>
                <CopyrightPara>Ayusha Opus Metal Art & Design Inc. and Nilesh Bhathella gives no warrenty and accepts no responsibility or liability for the accuracy or the completeness of the information and materials contained in this website.  Under no circumstances will Ayusha Opus Metal Art & Design Inc. and Nilesh Bhathella be held responsible or liable in any way for any claims, damages, losses, expenses, costs or liabilities whatsover (including, without limitation on any direct or indirect damages for loss of profits, business interruption or loss of information) resulting or arising directly or indirectly from your use of or inability to use this website or any websites or streaming videos or social media linked to it, or from your reliance on the information and material on this website, even if Ayusha Opus Metal Art & Design Inc. and Nilesh Bhathella has been advised of the possbility of such damages in advance.</CopyrightPara>
                <CopyrightPara>The views expressed at, or through this site are those of the individual authors writing in their individual capacities only.  All liability with respect to actions taken or not taken based on the contents of this site are hereby expressly disclaimed.  The content on this website is provided "as is", no representations are made that the content is error free.</CopyrightPara>

                <CopyrightSubHeading id='copyright'>Copyright</CopyrightSubHeading>
                <CopyrightPara>Ayusha Opus Metal Art & Design Inc. / Nilesh Bhathella</CopyrightPara>
                <CopyrightPara>© 2021 Ayusha Opus Metal Art & Design Inc. All rights reserved.</CopyrightPara>
                <CopyrightPara>© Nilesh Bhathella 2021 All rights reserved.</CopyrightPara>
                <CopyrightPara>The Artist, (Nilesh Bhathella and All Associated Works), rights are protected by the Canadian Copyright Act (R.S.C., 1985, c. C-42), Federal Copyright Act of 1976 and the Federal Visual Rights Act of 1990. Purchase of a copyright artwork does not transfer the copyright. The copyright to produce the works in copies, to produce derivative work based on the copywrited image, and distribute copies is retained by the Artist only. Any transfer of this copyright must be formally agreed to in writing expressly identifying what rights are being sold and for what purpose; otherwise all copyright and intellectual works of art are expressly and solely retained by the Artist (Nilesh Bhathella) and Ayusha Opus Metal Art & Design Inc.</CopyrightPara>
                <CopyrightPara>Works of art cannot be modified without the permission of the Artist. Works of art cannot be distributed, mutilated or modified in any way that would prejudice the reputation of the Artist.</CopyrightPara>
                <CopyrightPara>Main Video and Teaser Video are fully copyright by Ayusha Opus Metal Art & Design Inc. and Nilesh Bhathella.  No part whatsoever shall be reproduced or copied in any way.</CopyrightPara>
                <CopyrightPara>All works of art shown within this website are fully copyright by Ayusha Opus Metal Art & Design Inc. and Nilesh Bhathella.  No part whatsoever shall be reproduced or copied in any way.</CopyrightPara>
                <CopyrightPara>All photographs, pictures shown within this website are fully copyright by Ayusha Opus Metal Art & Design Inc. and Nilesh Bhathella.  No part whatsoever shall be reproduced or copied in any way.</CopyrightPara>
                <CopyrightPara>All written content within this website is fully copyright by Ayusha Opus Metal Art & Design Inc. and Nilesh Bhathella.  No part whatsoever shall be reproduced or copied in any way.</CopyrightPara>

                <CopyrightSubHeading>Copyright notice</CopyrightSubHeading>
                <CopyrightPara>1.1 Copyright © Ayusha Opus Metal Art & Design Inc.</CopyrightPara>
                <CopyrightPara>1.2 Copyright © Nilesh Bhathella</CopyrightPara>
                <CopyrightPara>1.3 Subject to the express provisions of this notice:</CopyrightPara>
                <CopyrightPara>(a) we, together with our licensors, own and control all the copyright and other intellectual property rights in this website and the material on this website; and</CopyrightPara>
                <CopyrightPara>(b) all the copyright and other intellectual property rights in this website and the material on this website is reserved.  This includes but is not limited to works posted on social media accounts e.g. Facebook, Twitter, Instagram and all other forms of media under the Ayusha Opus Metal Art & Design Inc. - AO logo Brand.</CopyrightPara>

                <CopyrightSubHeading>Copyright license</CopyrightSubHeading>
                <CopyrightPara>2.1 You may:</CopyrightPara>
                <CopyrightPara>(a) view pages from this website in a web browser;</CopyrightPara>
                <CopyrightPara>(b) stream audio and video files from our website</CopyrightPara>
                <CopyrightPara>(c) download pages from our website for the sole purpose of caching the downloaded pages within a web browser;</CopyrightPara>
                <CopyrightPara>2.2 You may not:</CopyrightPara>
                <CopyrightPara>(d) print and/or copy any pages or any items, pictures or videos from this website; and</CopyrightPara>
                <CopyrightPara>subject to the other provisions of this notice.</CopyrightPara>
                <CopyrightPara>2.3 Except as expressly permitted by the other provisions of this notice, you must not download any material from this website or save any such material to your computer or any other storage device.</CopyrightPara>
                <CopyrightPara>2.4 You may only use this website for your own personal purposes, and you must not use this website for any other purposes.</CopyrightPara>
                <CopyrightPara>2.5 Except as expressly permitted by this notice, you must not edit or otherwise modify any material on this website.</CopyrightPara>
                <CopyrightPara>2.6 Unless you own or control the relevant rights in the material, you must not:</CopyrightPara>
                <CopyrightPara>(a) republish material from this website (including republication on another website);</CopyrightPara>
                <CopyrightPara>(b) sell, rent or sub-license material from this website;</CopyrightPara>
                <CopyrightPara>(c) show any material from this website in public;</CopyrightPara>
                <CopyrightPara>(d) exploit material from this website for a commercial purpose; monetary gain; or</CopyrightPara>
                <CopyrightPara>(e) redistribute material from this website, save to the extent expressly permitted by this notice.</CopyrightPara>

                <CopyrightSubHeading>Acceptable use</CopyrightSubHeading>
                <CopyrightPara>3.1 You must not:</CopyrightPara>
                <CopyrightPara>(a) use our website in any way or take any action that causes, or may cause, damage to this website or impairment of the performance, availability or accessibility of this website;</CopyrightPara>
                <CopyrightPara>(b) use this website in any way that is unlawful, illegal, fraudulent or harmful, or in connection with any unlawful, illegal, fraudulent or harmful purpose or activity;</CopyrightPara>
                <CopyrightPara>(c) use this website to copy, store, host, transmit, send, use, publish or distribute any material which consists of (or is linked to) any spyware, computer virus, Trojan horse, worm, keystroke logger, rootkit or other malicious computer software; or</CopyrightPara>
                <CopyrightPara>(d) conduct any systematic or automated data collection activities (including without limitation scraping, data mining, data extraction and data harvesting) on or in relation to this website without our formal express written consent.</CopyrightPara>

                <CopyrightSubHeading>Report abuse</CopyrightSubHeading>
                <CopyrightPara>4.1 If you learn of any unlawful material or activity on this website, or any material or activity that breaches this notice, please let us know.</CopyrightPara>
                <CopyrightPara>4.2 You can let us know [by email].</CopyrightPara>

                <CopyrightSubHeading>Enforcement of copyright</CopyrightSubHeading>
                <CopyrightPara>5.1 We take the protection of our copyright very seriously.</CopyrightPara>
                <CopyrightPara>5.2 If we discover that you have used our copyright materials or works of art in contravention of the license set out in this notice, we may bring legal proceedings against you by the full force of the law, seeking monetary damages and/or an injunction to stop you using any and all copyright works of art and other materials. You will also be ordered to pay any legal costs pertaining to a contravention as stipulated above.</CopyrightPara>

                <CopyrightSubHeading>Permissions</CopyrightSubHeading>
                <CopyrightPara>6.1 You may request permission to use the copyright materials on this website by writing to us by email, using the contact details published on this website.  Failure to do so will result in a contravention as stipulated within Enforcement of Copyright.</CopyrightPara>

                <CopyrightSubHeading>Exceptions</CopyrightSubHeading>
                <CopyrightPara>Copyright holder and author is expressly Ayusha Opus Metal Art & Design Inc. and Nilesh Bhathella.</CopyrightPara>
                <CopyrightPara>Copyright belongs exclusively to the copyright owner (also called the rightsholder). Permission from the copyright owner is therefore needed to reproduce, display or communicate works such as any all all works of art, videos, pictures, articles or written submissions within this website.</CopyrightPara>

                <CopyrightSubHeading>Copyright term</CopyrightSubHeading>
                <CopyrightPara>All works are protected under the Copyright Act during the author’s lifetime and for another 50 years after the end of the calendar year of the author’s death. After that period ends, the art work comes into the public domain and can be used by anyone without permission unless Ayusha Metal Art & Design Inc. has legally transferred federal company ownership after author's death and as such the copyright shall be deemed to be part of that belonging to Ayusha Opus Metal Art & Design Inc. and the AO brand and shall continue with all copyrights and trademarks respectively.</CopyrightPara>
                <CopyrightPara>Works of art are protected for the term specified in the Copyright Act, in compliance with the principle of national treatment under the Berne Convention for the Protection of Literary and Artistic Works.</CopyrightPara>

                <CopyrightSubHeading>At the international level</CopyrightSubHeading>
                <CopyrightPara>Canada and USA has been a member of the World Intellectual Property Organization (WIPO) since 1970. It has also signed various international intellectual property treaties such as the Berne Convention for the Protection of Literary and Artistic Works (1886) which lays down the guiding principles for international copyright protection.</CopyrightPara>

                <CopyrightSubHeading>Copyright acknowledgement</CopyrightSubHeading>
                <CopyrightPara>This website contains copyrighted material the use of which has not always been specifically authorized by the copyright owner.  We are making such material available in our efforts to advance understanding of the philanthropic nature of our ethos to raise awareness for those less fortunate worldwide who are without basic sustanance of food and water and any collaborative effort in doing so is expressly much appreciated.  It is not our intention to use any copyrighted work for pusposes of redistribution or monetory gain by any means. This constitutes a "fair use" of any such copyrighted material as provided for in section 107 of the US Copyright Law.</CopyrightPara>
                <CopyrightPara>In accordance with Title 17 U.S.C. Section 107, the (certain) material on this site is distributed.  If you wish to use copyrighted material from this website for purposes of your own that go beyond "fair use", you must first obtain permission from the copyright owner.  </CopyrightPara>
                <CopyrightPara>Please email us with any such requests prior to doing so as it will be deemed an infringement otherwise.</CopyrightPara>
                <CopyrightPara>Certain product and company names used are trademarks™ or registered ® trademarks of their respective holders.  Use of them does not imply any affiliation with or endorsement by them.</CopyrightPara>
                <CopyrightPara>Authors of certain works used within this website that is not copyright by Ayusha Opus Metal Art & Design Inc. and Nilesh Bhathella or their appointed representatives have the authority to do the following:</CopyrightPara>
                <CopyrightPara>Produce, reproduce or publish their own work or any substantial part in any material form; play or perform their own work or any substantial part in public</CopyrightPara>
                <CopyrightPara>Produce, reproduce, perform or publish any translation of their own work</CopyrightPara>
                <CopyrightPara>Make any sound or cinematographic recording of their literary, dramatic or musical work</CopyrightPara>
                <CopyrightPara>Reproduce, adapt and publicly present their own literary, dramatic, musical or artistic work as a cinematographic work</CopyrightPara>
                <CopyrightPara>Communicate their literary, dramatic, musical or artistic work to the public by telecommunication</CopyrightPara>
                <CopyrightPara>Present their artistic work at a public exhibition</CopyrightPara>
                <CopyrightPara>Authors or their representatives are also responsible for authorizing the above actions.</CopyrightPara>

                <CopyrightSubHeading id='trademarks'>Trademarks</CopyrightSubHeading>
                <CopyrightPara>Pursuant to Trademarks Act (R.S.C., 1985, c. T-13) and any subsequent Trademark laws and stipulations; AO and Ayusha Opus Metal Art & Design Inc. are registered trademarks and are not to be used in any form whatsoever by any persons and/or companies for any purpose. The use of AO, Ayusha Opus, Ayusha Opus Metal Art & Design Inc. and Nilesh Bhathella has been used throughout this website and is expressly deemed to be acknowledged as a registered trademark by any persons using this website.</CopyrightPara>
                <CopyrightPara>AO® All Rights Reserved.</CopyrightPara>
                <CopyrightPara>AO™ All Rights Reserved.</CopyrightPara>
                <CopyrightPara>Ayusha Opus Metal Art & Design Inc.® All Rights Reserved.</CopyrightPara>
                <CopyrightPara>Ayusha Opus Metal Art & Design Inc.™ All Rights Reserved.</CopyrightPara>

                <CopyrightSubHeading>Trademark notice</CopyrightSubHeading>
                <CopyrightPara>Except for purposes of identification of Products or Services, no right, title, interest, or license in or to any trademark or service mark of Licensor is granted to Distributor under this Agreement. Distributor may on its business cards state that Distributor is an authorized distributor for the licensing of the Products and provision of Services of Licensor only via written consent of the Trademark owner (Ayusha Opus Metal Art & Design Inc. and Nilesh Bhathella). Distributor shall not contest the validity of such marks or Licensor's exclusive ownership of them. During the term of this Agreement, Distributor shall not adopt, use, or register, whether as a corporate name, trademark, service mark or other indication of origin, any such marks, or any word or mark confusingly similar to them in any jurisdiction worldwide</CopyrightPara>

                <CopyrightSubHeading>Intellectual Property Ownership - Works of Art</CopyrightSubHeading>
                <CopyrightPara>Ayusha Opus Metal Art & Design Inc. and Nilesh Bhathella alone (and its licensors, where applicable) shall own all right, title and interest, including all related Intellectual Property Rights, in and to the Ayusha Opus Metal Art & Design Inc. Technology, the Content and the Works of Art and/or Services and any suggestions, ideas, enhancement requests, feedback, recommendations or other information provided by you or any other party relating to the Works of Art and/or Services. This Agreement is not a sale and does not convey to you any rights of ownership in or related to the Art and/or Services and any and all Works of Art and/or Technology and the Intellectual Property Rights are exclusively owned by Ayusha Opus Metal Art & Design Inc. and Nilesh Bhathella. The Ayusha Opus name, the AO logo, and the product names associated with the Works of Art and/or Services are trademarks of Ayusha Opus Metal Art & Design and Nilesh Bhathella, and no right or license is granted to use them in any form whatsoever.</CopyrightPara>

                <CopyrightSubHeading>Intellectual Property Ownership - Website</CopyrightSubHeading>
                <CopyrightPara>The website and its related software are the intellectual property of and are owned by AO® Ayusha Opus Metal Art & Design Inc. and Nilesh Bhathella.  The structure, organization, and code of the website and its related software contain valuable trade secrets and confidential information of AO® Ayusha Opus Metal Art & Design Inc. and Nilesh Bhathella.  Except as expressly stated herein, this Agreement does not grant you any intellectual property rights whatsoever in the website and its related software and all rights are reserved by AO® Ayusha Opus Metal Art & Design Inc. and Nilesh Bhathella.  Any form, database, or software that is altered, conceived, made, or developed in whole or in part by AO® Ayusha Opus Metal Art & Design Inc. and Nilesh Bhathella.  (including any developed jointly) during or as a result of our relationship with any persons and/or companies, they shall become and remain the sole and exclusive property of AO® Ayusha Opus Metal Art & Design Inc. and Nilesh Bhathella. You agree to make no claim in the rights or ownership of any such form, database or software. To the extent that any custom form is created by AO® Ayusha Opus Metal Art & Design Inc. and Nilesh Bhathella for you, based upon any prior form, template or exemplar provided by you, you warrant and represent to AO® Ayusha Opus Metal Art & Design Inc. and Nilesh Bhathella.  that you created said form(s) or have the legal right to use said form(s). You agree to indemnify AO® Ayusha Opus Metal Art & Design Inc. and Nilesh Bhathella for any third-party claims for infringement, misappropriation or other violation of any third-party’s intellectual property rights where such claims are made against AO® Ayusha Opus Metal Art & Design Inc. and Nilesh Bhathella for forms, templates or exemplars created based upon material provided by you to AO® Ayusha Opus Metal Art & Design Inc. and Nilesh Bhathella.</CopyrightPara>
                
                {/* <CopyrightSubHeading>In Short</CopyrightSubHeading>
                <CopyrightPara>What I am saying is:</CopyrightPara>
                <CopyrightPara>Do not copy any of my work in any way for any reason, that's it!</CopyrightPara>
                <CopyrightPara>Thank you ;)</CopyrightPara>
                <CopyrightPara>Sincerely,</CopyrightPara>
                <CopyrightPara>Nilesh Bhathella</CopyrightPara>
                <CopyrightPara>Ayusha Opus Metal Art & Design Inc.</CopyrightPara> */}
                
                {/* <CopyrightEndBorder /> */}
                <CopyrightHomeAccordian onCursor={onCursor} />

            </CopyrightMain>
        </ThemeProvider>
    )
};